import { ContentCopy } from '@mui/icons-material';
import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu as MuiMenu,
  Tooltip,
} from '@mui/material';
import { type ElementType } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDeleteTVBrand } from '~/api/tv-brands/delete';
import { useDuplicateTVBrand } from '~/api/tv-brands/duplicate';
import { ActionButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { MoreDeleteAction } from '~/components/table';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useMoreActions } from '~/hooks/table';
import { assert } from '~/lib/assert';
import { type NetworkTvBrands__TvBrand as TVBrand } from '../../queries/list.generated';

export interface MenuProps {
  tvBrand: TVBrand;
  Icon?: ElementType;
}

export const Menu = ({ tvBrand }: MenuProps) => {
  const { currentNetwork } = useAppContext();

  const [moreMenuProps, moreActionProps, moreTableActionProps] = useMoreActions<TVBrand>();
  const tvBrandActionProps = moreTableActionProps(tvBrand);
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();
  const [deleteTvBrand] = useDeleteTVBrand();
  const [duplicateTvBrand] = useDuplicateTVBrand();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() || '';

  return (
    <>
      <ActionButton {...tvBrandActionProps} />
      <MuiMenu {...moreMenuProps}>
        <MenuItem
          onClick={async () => {
            await duplicateTvBrand({
              variables: { networkId: currentNetwork.id, search, tvBrandId: tvBrand.id },
            });
            moreMenuProps.onClose();
          }}
        >
          <ListItemIcon>
            <ContentCopy />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
        <Divider />

        <Tooltip title={tvBrand.canDestroy.reasons?.fullMessages[0]}>
          <div>
            <MoreDeleteAction
              disabled={!tvBrand.canDestroy.value}
              {...moreActionProps}
              onClick={async () => {
                assert(moreMenuProps.context !== undefined);
                if (!(await confirmDelete())) return;
                await deleteTvBrand({
                  variables: { id: moreMenuProps.context.id, search },
                });
                moreMenuProps.onClose();
              }}
            />
          </div>
        </Tooltip>
      </MuiMenu>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt={
          <span>
            Deleting this TV Brand will remove it from the system.{' '}
            <b>This operation may not be undone.</b>
          </span>
        }
        title="Delete TV Brand"
      />
    </>
  );
};
