import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { CreateFontDocument } from './create.generated';

export const useCreateFont = (options: ApiOptions<typeof CreateFontDocument> = {}) =>
  useMutation(CreateFontDocument, {
    ...useNotifications(CreateFontDocument, {
      failure: (error) =>
        error.message || 'An error occurred. Reload and try again or contact support.',
      success: 'Font created',
    }),
    ...options,
  });
