import { FilterListSidebar } from '.';

interface FilterSidebarProps {
  open: boolean;
  children: React.ReactNode; // Accepts any content as children
}

export const FilterSidebar = ({ open, children }: FilterSidebarProps) => {
  return <FilterListSidebar className={open ? '' : 'hidden'}>{children}</FilterListSidebar>;
};
