import { Button } from '@mui/material';
import type { DeviceStatus } from '~/generated/graphql';
import { useDeviceFilters } from '../context';
import { StatusToggleContainer } from '../lib';

export const StatusToggle = () => {
  const { setFilter, ...filters } = useDeviceFilters();

  const isActive = (desired: DeviceStatus[]) => {
    const selected = filters.status.filter((f) => f.selected).map((f) => f.value);

    if (selected.length !== desired.length) return false;
    return desired.every((status) => selected.includes(status));
  };
  return (
    <StatusToggleContainer>
      <Button
        size="small"
        variant="contained"
        onClick={() =>
          setFilter({
            field: 'status',
            value: [
              { label: 'Online', selected: false, value: 'HEALTHY' },
              { label: 'Offline', selected: false, value: 'ERROR' },
              { label: 'Warning', selected: false, value: 'WARN' },
            ],
          })
        }
        sx={{
          backgroundColor: isActive([]) ? 'common.white' : 'transparent',
          color: isActive([]) ? 'primary.main' : '#969696',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
      >
        All
      </Button>

      <Button
        size="small"
        variant="contained"
        onClick={() =>
          setFilter({
            field: 'status',
            value: [
              { label: 'Online', selected: true, value: 'HEALTHY' },
              { label: 'Offline', selected: false, value: 'ERROR' },
              { label: 'Warning', selected: false, value: 'WARN' },
            ],
          })
        }
        sx={{
          backgroundColor: isActive(['HEALTHY']) ? 'common.white' : 'transparent',
          color: isActive(['HEALTHY']) ? 'primary.main' : '#969696',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
      >
        Online
      </Button>

      <Button
        size="small"
        variant="contained"
        onClick={() =>
          setFilter({
            field: 'status',
            value: [
              { label: 'Online', selected: false, value: 'HEALTHY' },
              { label: 'Offline', selected: true, value: 'ERROR' },
              { label: 'Warning', selected: false, value: 'WARN' },
            ],
          })
        }
        sx={{
          backgroundColor: isActive(['ERROR']) ? 'common.white' : 'transparent',
          color: isActive(['ERROR']) ? 'primary.main' : '#969696',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
      >
        Offline
      </Button>
    </StatusToggleContainer>
  );
};
