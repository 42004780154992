import { Button, type DialogProps } from '@mui/material';
import { useUpdateChannel } from '~/api/channels';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { useChannelFormik } from '../lib/channel-formik';
import { ChannelForm } from './ChannelForm';
import type { EditChannelDialogProps__Channel } from './EditChannelDialog.generated';

/* GraphQL */ `#graphql
fragment EditChannelDialogProps__Channel on Channel {
  backgroundColor
  encryption
  id
  name
  number
  thumbnailFile {
    uri
  }
  url
  urls
}
`;

export interface EditChannelDialogProps extends Omit<DialogProps, 'children'> {
  channel: EditChannelDialogProps__Channel;
  close: () => void;
}

export const EditChannelDialog = ({ channel, close, ...props }: EditChannelDialogProps) => {
  const [updateChannel] = useUpdateChannel();

  const formik = useChannelFormik(
    {
      backgroundColor: channel.backgroundColor,
      encryption: channel.encryption,
      name: channel.name,
      number: channel.number,
      thumbnailUri: channel.thumbnailFile.uri,
      url: channel.url,
      urls: channel.urls,
    },
    async (values) => {
      const patch = { ...values, thumbnailUri: values.thumbnailUri.trim() || undefined };
      await updateChannel({ variables: { channelId: channel.id, patch } });
      close();
    },
  );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={close}
      TransitionProps={{ onExited: () => formik.resetForm() }}
      {...props}
    >
      <DialogTitle onClose={close}>Edit Channel</DialogTitle>

      <DialogContent>
        <ChannelForm
          formik={formik}
          id="edit-channel-form"
          thumbnailFileUri={channel.thumbnailFile.uri}
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={close} variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          form="edit-channel-form"
          type="submit"
          variant="contained"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
