import { Box, styled, useTheme } from '@mui/material';
import type { ReactChildren, ReactNode } from 'react';
import { useAppContext } from '~/contexts';

export const ListActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(1),
}));

export const ListPageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export interface ListContainerProps {
  children: ReactNode | ReactChildren;
  filterOpen?: boolean;
}

export const ListContainer = ({ children, filterOpen = true }: ListContainerProps) => {
  const { impersonating } = useAppContext();
  const theme = useTheme();
  return (
    <Box
      sx={{
        flex: 1,
        transition: 'margin-left 0.1s ease-in-out',
        marginLeft: filterOpen ? 0 : '-250px',
        position: 'relative',
        width: '100%',
        height: `calc(100vh - 45px - ${impersonating ? 50 : 0}px)`,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          marginLeft: 0,
          marginTop: filterOpen ? 0 : 'calc(425px - 100vh)',
          transition: 'margin-top 0.1s ease-in-out',
          padding: theme.spacing(1),
        },
      }}
    >
      {children}
    </Box>
  );
};

export const ListHeader = styled(Box)(() => ({
  position: 'sticky',
  flexShrink: 0,
  padding: 0,
}));

const StyledListContent = styled(Box)<{ impersonating: boolean }>(({ theme, impersonating }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  [theme.breakpoints.down('sm')]: {
    height: `calc(100vh - 305px - ${impersonating ? 50 : 0}px)`,
  },
}));

export const ListContent = (props: React.ComponentProps<typeof Box>) => {
  const { impersonating } = useAppContext();

  return <StyledListContent impersonating={impersonating} {...props} />;
};

export const ListFooter = styled(Box)(() => ({
  position: 'sticky',
  bottom: 0,
  flexShrink: 0,
  padding: 0,
}));
