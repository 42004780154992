import { Box, Chip } from '@mui/material';
import { useDeviceFilters } from '../context';

export const FilterChips = () => {
  const { setFilter, ...filters } = useDeviceFilters();

  return (
    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
      {filters.arch
        .filter((f) => f.selected)
        .map((f) => (
          <Chip
            key={`arch-${f.value}`}
            label={`Arch: ${f.label}`}
            color="primary"
            onDelete={() => {
              const deselected = filters.arch.map((x) =>
                x.value === f.value ? { ...x, selected: false } : x,
              );
              setFilter({ field: 'arch', value: deselected });
            }}
          />
        ))}
      {filters.demo
        .filter((f) => f.selected)
        .map((f) => (
          <Chip
            key={`demo-${f.value}`}
            label={`Demo: ${f.label}`}
            color="primary"
            onDelete={() => {
              const deselected = filters.demo.map((x) =>
                x.value === f.value ? { ...x, selected: false } : x,
              );
              setFilter({ field: 'demo', value: deselected });
            }}
          />
        ))}
      {filters.displayOn
        .filter((f) => f.selected)
        .map((f) => (
          <Chip
            key={`display-${f.value}`}
            label={`Display: ${f.label}`}
            color="primary"
            onDelete={() => {
              const deselected = filters.displayOn.map((x) =>
                x.value === f.value ? { ...x, selected: false } : x,
              );
              setFilter({ field: 'displayOn', value: deselected });
            }}
          />
        ))}
      {filters.groups
        .filter((f) => f.selected)
        .map((f) => (
          <Chip
            key={`group-${f.value}`}
            label={`Group: ${f.label}`}
            color="primary"
            onDelete={() => {
              const deselected = filters.groups.map((x) =>
                x.value === f.value ? { ...x, selected: false } : x,
              );
              setFilter({ field: 'groups', value: deselected });
            }}
          />
        ))}
      {filters.internal
        .filter((f) => f.selected)
        .map((f) => (
          <Chip
            key={`internal-${f.value}`}
            label={`Internal: ${f.label}`}
            color="primary"
            onDelete={() => {
              const deselected = filters.internal.map((x) =>
                x.value === f.value ? { ...x, selected: false } : x,
              );
              setFilter({ field: 'internal', value: deselected });
            }}
          />
        ))}
      {filters.kind
        .filter((f) => f.selected)
        .map((f) => (
          <Chip
            key={`kind-${f.value}`}
            label={`Type: ${f.label}`}
            color="primary"
            onDelete={() => {
              const deselected = filters.kind.map((x) =>
                x.value === f.value ? { ...x, selected: false } : x,
              );
              setFilter({ field: 'kind', value: deselected });
            }}
          />
        ))}
      {filters.silence
        .filter((f) => f.selected)
        .map((f) => (
          <Chip
            key={`silence-${f.value}`}
            label={`Silenced: ${f.label}`}
            color="primary"
            onDelete={() => {
              const deselected = filters.silence.map((x) =>
                x.value === f.value ? { ...x, selected: false } : x,
              );
              setFilter({ field: 'silence', value: deselected });
            }}
          />
        ))}
      {filters.status
        .filter((f) => f.selected)
        .map((f) => (
          <Chip
            key={`status-${f.value}`}
            label={`Status: ${f.label}`}
            color="primary"
            onDelete={() => {
              const deselected = filters.status.map((x) =>
                x.value === f.value ? { ...x, selected: false } : x,
              );
              setFilter({ field: 'status', value: deselected });
            }}
          />
        ))}
      {filters.tags
        .filter((f) => f.selected)
        .map((f) => (
          <Chip
            key={`tag-${f.value}`}
            label={`Tag: ${f.label}`}
            color="primary"
            onDelete={() => {
              const updated = filters.tags.map((x) =>
                x.value === f.value ? { ...x, selected: false } : x,
              );
              setFilter({ field: 'tags', value: updated });
            }}
          />
        ))}
    </Box>
  );
};
