import { Box } from '@mui/material';
import type { PasswordStrength } from '~/lib/password';
import { PasswordStrengthMeter } from '../PasswordStrengthMeter';

export const PasswordStrengthMeterBox = ({
  strength,
}: {
  strength: PasswordStrength | undefined;
}) => {
  return (
    <Box
      sx={{
        transition: 'all 300ms ease-in-out',
        opacity: strength ? 1 : 0,
        height: strength ? 'auto' : 0,
        overflow: 'hidden',
        marginBottom: '4px',
      }}
    >
      <PasswordStrengthMeter strength={strength} />
    </Box>
  );
};
