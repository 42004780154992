import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { CreateTvBrandDocument } from './create.generated';

export const useCreateTVBrand = (options: ApiOptions<typeof CreateTvBrandDocument> = {}) =>
  useMutation(CreateTvBrandDocument, {
    ...useNotifications(CreateTvBrandDocument, {
      failure: (error) =>
        error.message || 'An error occurred. Reload and try again or contact support.',
      success: 'TV Brand created.',
    }),
    ...options,
  });
