// Use the short_name in admin for now
// Tried to sort these by frequency of use, roughly
export const leagues = [
  { key: 'Major League Baseball', value: 'mlb' },
  { key: 'National Football League', value: 'nfl' },
  { key: 'NCAA Football', value: 'ncaaf' },
  { key: `NCAA Men's Basketball`, value: 'mncaab' },
  { key: `NCAA Women's Basketball`, value: 'wncaab' },
  { key: 'National Basketball Association', value: 'nba' },
  { key: 'National Hockey League', value: 'nhl' },
  { key: 'MLS', value: 'mls' },
  { key: 'NCAA Baseball', value: 'ncaab' },
  { key: 'NCAA Volleyball', value: 'wncaav' },
  { key: 'NCAA Gymnastics', value: 'ncaag' },
  { key: `NCAA Men's Hockey`, value: 'ncaah' },
  { key: 'NCAA Wrestling', value: 'ncaaw' },
  { key: 'WNBA', value: 'wnba' },
  { key: 'Minor League Baseball', value: 'milb' },
  { key: 'Premier League', value: 'epl' },
  { key: 'Arena Football League', value: 'afl' },
] as const;
