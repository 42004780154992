import {
  InstallDesktop,
  Layers,
  LiveTv,
  OfflineBolt,
  PlayArrow,
  Send,
  SwapHoriz,
  VolumeUp,
} from '@mui/icons-material';
import { Button } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useSendDeviceCommand } from '~/api/devices';
import { TransferDialog, useTransferDeviceDialog } from '~/components/devices/transfer-dialog';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { SettingsBody, SettingsData, SettingsHead } from '~/components/settings';
import { VerticalButton } from '~/components/vertical-button';
import { useAppContext } from '~/contexts';
import { DeviceCommandKind, DeviceKind } from '~/generated/graphql';
import { useConfirmDialog } from '~/hooks/dialogs';
import {
  PlayOverlayDialog,
  SamsungFirmwareDialog,
  TriggerViewDialog,
  TuneChannelDialog,
  UpdateSoftwareDialog,
  VolumeDialog,
  WebosFirmwareDialog,
} from '../../components';
import { useDevice } from '../context';
import { INITIAL_COMMANDS, type Command } from '../lib';

export const Commands = () => {
  const { channels, device } = useDevice();
  const { currentNetwork, currentUser } = useAppContext();
  const [confirmCommand, confirmCommandProps] = useConfirmDialog();
  const [selectedCommand, setSelectedCommand] = useState<Command>();
  const [sendCommand] = useSendDeviceCommand();
  const [openTransferDialog, transferDialogProps] = useTransferDeviceDialog();
  const [triggerDialogOpen, setTriggerDialogOpen] = useState(false);
  const [overlayDialogOpen, setOverlayDialogOpen] = useState(false);
  const [updateSoftwareDialogOpen, setUpdateSoftwareDialogOpen] = useState(false);
  const [tuneChannelDialogOpen, setTuneChannelDialogOpen] = useState(false);
  const [volumeDialogOpen, setVolumeDialogOpen] = useState(false);
  const [samsungFirmwareDialogOpen, setSamsungFirmwareDialogOpen] = useState(false);
  const [webosFirmwareDialogOpen, setWebosFirmwareDialogOpen] = useState(false);

  const commands: Command[] = useMemo(() => {
    const inactiveEnvs = [...new Set(device.inactiveEnvironments)];
    return [
      ...INITIAL_COMMANDS,
      ...(currentUser.admin
        ? inactiveEnvs.map((x) => ({
            Icon: SwapHoriz,
            command: DeviceCommandKind.Environment,
            description: `This will move the device to the ${x} environment.`,
            payload: { environment: x },
            title: `Move to ${x}`,
          }))
        : []),
    ];
  }, [currentUser, device]);

  const handleClick = useCallback(
    async (command: Command) => {
      setSelectedCommand(command);
      if (!(await confirmCommand())) return;
      await sendCommand({
        variables: {
          input: {
            command: command.command,
            selected: { ids: [device.id] },
            payload: command.payload || {},
          },
        },
      });
    },
    [confirmCommand, device, sendCommand],
  );

  if (!device.canUpdate.value) return null;

  return (
    <>
      <SettingsHead>Commands</SettingsHead>
      <SettingsBody>
        <SettingsData className="last scroll no-space-between">
          {commands.map((command) => (
            <VerticalButton
              Icon={command.Icon}
              key={command.title}
              onClick={async () => await handleClick(command)}
              title={command.title}
            />
          ))}
          <VerticalButton
            Icon={Layers}
            disabled={!device.realtime}
            onClick={() => setOverlayDialogOpen(true)}
            title="Play Overlay"
          />
          <VerticalButton
            Icon={PlayArrow}
            disabled={device.activeShow == null || device.activeShow.show.viewCount < 2}
            onClick={() => setTriggerDialogOpen(true)}
            title="Play View"
          />
          <VerticalButton
            Icon={LiveTv}
            disabled={channels.length === 0}
            onClick={() => setTuneChannelDialogOpen(true)}
            title="Tune Channel"
          />
          {currentUser.admin && (
            <VerticalButton
              Icon={SwapHoriz}
              onClick={() => openTransferDialog(device)}
              title="Transfer"
            />
          )}
          {currentNetwork.canManage.value && (
            <VerticalButton
              Icon={OfflineBolt}
              disabled={!device.canUpdateSoftware.value}
              onClick={() => setUpdateSoftwareDialogOpen(true)}
              title="Update Software"
            />
          )}
          {device.kind === DeviceKind.Samsung && currentNetwork.canManage.value && (
            <VerticalButton
              Icon={InstallDesktop}
              disabled={
                device.metadata == null ||
                !('model' in device.metadata) ||
                !('firmware' in device.metadata)
              }
              onClick={() => setSamsungFirmwareDialogOpen(true)}
              title="Install Firmware"
            />
          )}
          {device.kind === DeviceKind.Webos && currentNetwork.canManage.value && (
            <VerticalButton
              Icon={InstallDesktop}
              disabled={
                device.metadata == null ||
                !('modelName' in device.metadata) ||
                !('firmwareVersion' in device.metadata)
              }
              onClick={() => setWebosFirmwareDialogOpen(true)}
              title="Install Firmware"
            />
          )}
          {(device.kind === DeviceKind.Samsung || device.kind === DeviceKind.Webos) && (
            <VerticalButton
              Icon={VolumeUp}
              onClick={() => setVolumeDialogOpen(true)}
              title="Set Volume"
            />
          )}
        </SettingsData>
      </SettingsBody>

      <ConfirmDialog
        {...confirmCommandProps}
        confirmButton={
          <Button onClick={confirmCommandProps.onConfirm} startIcon={<Send />} variant="contained">
            Confirm
          </Button>
        }
        prompt={<span>{selectedCommand?.description}</span>}
        title={`Send Device Command`}
      />
      <TransferDialog {...transferDialogProps} />
      {overlayDialogOpen && (
        <PlayOverlayDialog
          selected={{ ids: [device.id] }}
          onClose={() => setOverlayDialogOpen(false)}
          open
        />
      )}
      {triggerDialogOpen && <TriggerViewDialog setOpen={setTriggerDialogOpen} />}
      {tuneChannelDialogOpen && (
        <TuneChannelDialog
          selected={{ ids: [device.id] }}
          onClose={() => setTuneChannelDialogOpen(false)}
          open
        />
      )}
      {updateSoftwareDialogOpen && (
        <UpdateSoftwareDialog
          selected={{ ids: [device.id] }}
          onClose={() => setUpdateSoftwareDialogOpen(false)}
        />
      )}
      {samsungFirmwareDialogOpen && (
        <SamsungFirmwareDialog
          deviceId={device.id}
          handleClick={handleClick}
          onClose={() => setSamsungFirmwareDialogOpen(false)}
        />
      )}
      {webosFirmwareDialogOpen && (
        <WebosFirmwareDialog
          deviceId={device.id}
          handleClick={handleClick}
          onClose={() => setWebosFirmwareDialogOpen(false)}
        />
      )}
      {volumeDialogOpen && (
        <VolumeDialog
          selected={{ ids: [device.id] }}
          onClose={() => setVolumeDialogOpen(false)}
          open
        />
      )}
    </>
  );
};
