import { styled } from '@mui/material';

export const ShowActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
