import { FormatAlignCenter, FormatAlignLeft, FormatAlignRight } from '@mui/icons-material';
import {
  Box,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  Slider,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { MuiColorInput } from 'mui-color-input';
import { useCallback, type MouseEvent } from 'react';
import { useStudio, type LetterCase, type StudioItem } from '../../context';
import {
  StudioSideBySide,
  StudioSideBySideContainer,
  StudioSidebarInterior,
  StudioSidebarTitle,
  transformText,
} from '../lib';

export interface StudioSettingsFormatTabTextProps {
  selectedItem: Extract<StudioItem, { type: 'text' }>;
}

export const StudioSettingsFormatTabText = ({ selectedItem }: StudioSettingsFormatTabTextProps) => {
  const { fonts, updateItems } = useStudio();

  const handleAlignment = useCallback(
    (_: MouseEvent, align: 'center' | 'left' | 'right') => {
      updateItems([
        {
          align,
          id: selectedItem.id,
        },
      ]);
    },
    [selectedItem.id, updateItems],
  );

  const handleTransform = useCallback(
    (_: MouseEvent, transform: LetterCase) => {
      updateItems([
        {
          id: selectedItem.id,
          __textTransform: transform,
          text: transformText(selectedItem.text || '', transform),
        },
      ]);
    },
    [selectedItem.id, selectedItem.text, updateItems],
  );

  if (!fonts) return null;

  const customFonts = fonts.filter((x) => x.__fontId != null);
  const systemFonts = fonts.filter((x) => x.__fontId == null);

  return (
    <StudioSidebarInterior>
      <StudioSidebarTitle>Alignment</StudioSidebarTitle>

      <ToggleButtonGroup
        aria-label="text alignment"
        exclusive
        fullWidth
        onChange={handleAlignment}
        value={selectedItem.align}
      >
        <Tooltip title="Left align text">
          <ToggleButton aria-label="left aligned" value="left">
            <FormatAlignLeft />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Center text">
          <ToggleButton aria-label="centered" value="center">
            <FormatAlignCenter />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Right align text">
          <ToggleButton aria-label="right aligned" value="right">
            <FormatAlignRight />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>

      <StudioSidebarTitle>Format</StudioSidebarTitle>

      <StudioSidebarInterior>
        <MuiColorInput
          format="hex"
          isAlphaHidden={true}
          onChange={(fill) => updateItems([{ id: selectedItem.id, fill }])}
          value={typeof selectedItem.fill === 'string' ? selectedItem.fill : '#000000'}
        />

        <Box>
          <Select
            onChange={(event) => {
              const font = fonts.find((x) => x.value === event.target.value);
              if (!font) return;
              updateItems([
                { __fontId: font.__fontId, id: selectedItem.id, fontFamily: font.value },
              ]);
            }}
            value={selectedItem.fontFamily}
            fullWidth
            sx={{ '& .MuiSelect-select': { paddingY: '16.5px' } }}
          >
            {customFonts.length > 0 && <ListSubheader>Custom</ListSubheader>}
            {customFonts.map(({ key, value }) => (
              <MenuItem key={key} value={value} sx={{ fontFamily: value, fontSize: 20, py: 1 }}>
                {key}
              </MenuItem>
            ))}
            {systemFonts.length > 0 && <ListSubheader>System</ListSubheader>}
            {systemFonts.map(({ key, value }) => (
              <MenuItem key={key} value={value} sx={{ fontFamily: value, fontSize: 20, py: 1 }}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <StudioSideBySideContainer>
          <StudioSideBySide>
            <TextField
              InputProps={{ endAdornment: <InputAdornment position="end">px</InputAdornment> }}
              onChange={(event) =>
                updateItems([{ id: selectedItem.id, fontSize: Number(event.target.value) }])
              }
              type="number"
              value={selectedItem.fontSize || 6}
            />
          </StudioSideBySide>
          <StudioSideBySide>
            <Slider
              min={6}
              max={200}
              onChange={(_, fontSize) => updateItems([{ id: selectedItem.id, fontSize }])}
              step={1}
              value={selectedItem.fontSize || 6}
              valueLabelDisplay="auto"
            />
          </StudioSideBySide>
        </StudioSideBySideContainer>

        <ToggleButtonGroup
          aria-label="text transform"
          exclusive
          fullWidth
          onChange={handleTransform}
          value={selectedItem.__textTransform}
        >
          <Tooltip title="Uppercase text">
            <ToggleButton
              aria-label="transform uppercase"
              sx={{ fontWeight: 'bold', textTransform: 'none' }}
              value="uppercase"
            >
              TT
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Capitalize text">
            <ToggleButton
              aria-label="transform capitalize"
              sx={{ fontWeight: 'bold', textTransform: 'none' }}
              value="capitalize"
            >
              Tt
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Lowercase text">
            <ToggleButton
              aria-label="transform lowercase"
              sx={{ fontWeight: 'bold', textTransform: 'none' }}
              value="lowercase"
            >
              tt
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>

        <StudioSideBySideContainer>
          <StudioSideBySide>
            <StudioSidebarTitle>Line Height</StudioSidebarTitle>
          </StudioSideBySide>
          <StudioSideBySide>
            <Slider
              min={1}
              max={5}
              onChange={(_, lineHeight) => updateItems([{ id: selectedItem.id, lineHeight }])}
              step={0.1}
              value={selectedItem.lineHeight ?? 1}
              valueLabelDisplay="auto"
            />
          </StudioSideBySide>
        </StudioSideBySideContainer>

        <StudioSideBySideContainer>
          <StudioSideBySide>
            <StudioSidebarTitle>Letter Spacing</StudioSidebarTitle>
          </StudioSideBySide>
          <StudioSideBySide>
            <Slider
              min={0}
              max={100}
              onChange={(_, letterSpacing) => updateItems([{ id: selectedItem.id, letterSpacing }])}
              step={1}
              value={selectedItem.letterSpacing ?? 0}
              valueLabelDisplay="auto"
            />
          </StudioSideBySide>
        </StudioSideBySideContainer>
      </StudioSidebarInterior>
    </StudioSidebarInterior>
  );
};
