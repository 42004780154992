import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { DuplicateTvBrandDocument } from './duplicate.generated';

export const useDuplicateTVBrand = (options: ApiOptions<typeof DuplicateTvBrandDocument> = {}) =>
  useMutation(DuplicateTvBrandDocument, {
    ...useNotifications(DuplicateTvBrandDocument, {
      failure: (error) =>
        error.message || 'An error occurred. Reload and try again or contact support.',
      success: 'Duplicated TV Brand',
    }),

    ...options,
  });
