import { useQuery } from '@apollo/client';
import { PlayArrow } from '@mui/icons-material';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { useState } from 'react';
import { useSendDeviceCommand } from '~/api/devices';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { ViewSelect } from '~/components/shows/view-select';
import { useAppContext } from '~/contexts';
import { DeviceCommandKind } from '~/generated/graphql';
import { assert } from '~/lib/assert';
import { TriggerViewDialogDocument } from '../queries/queries.generated';
import { useDevice } from '../show/context';

interface TriggerViewDialogProps {
  setOpen: (open: boolean) => void;
}

export const TriggerViewDialog = ({ setOpen }: TriggerViewDialogProps) => {
  const { currentNetwork } = useAppContext();
  const { device } = useDevice();

  const { data } = useQuery(TriggerViewDialogDocument, {
    skip: device.activeShow == null,
    variables: { networkId: currentNetwork.id, showId: device.activeShow?.show.id as number },
  });

  const show = data?.network?.show;

  const [viewId, setViewId] = useState<number | undefined>(device.currentView?.id);

  const [sendCommand, { called }] = useSendDeviceCommand();

  const close = () => setOpen(false);

  const play = () => {
    // Send id and name...whatever works
    const payload = show?.views.find((view) => view.id === viewId);
    assert(payload != null, 'TriggerViewDialog: No view selected');
    void sendCommand({
      variables: {
        input: { command: DeviceCommandKind.TriggerView, selected: { ids: [device.id] }, payload },
      },
    }).then(close);
  };

  return (
    <Dialog fullWidth maxWidth="xs" onClose={close} open>
      <DialogTitle onClose={close}>Play View</DialogTitle>

      <DialogContent>
        <DialogContentTitle>Select a view to play on this device:</DialogContentTitle>

        <ViewSelect
          disabled={called}
          fullWidth
          onChange={setViewId}
          show={show}
          sx={{ fontSize: '1rem' }}
          value={viewId}
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={called} onClick={close} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={viewId == null || called}
          onClick={play}
          startIcon={<PlayArrow />}
          variant="contained"
        >
          Play View
        </Button>
      </DialogActions>
    </Dialog>
  );
};
