import { useQuery } from '@apollo/client';
import { Stadium } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useDeleteEventType } from '~/api/event-types/delete';
import { useUpdateEventType } from '~/api/event-types/update';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { DeleteButton, SaveButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import {
  SettingDescription,
  SettingInputWrapper,
  SettingLabelWrapper,
  SettingName,
  SettingsBody,
  SettingsData,
  SettingsHead,
} from '~/components/settings';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useLink } from '~/hooks/link';
import { Timestamps } from '../../components/timestamps';
import { EventTypeForm, eventTypeSchema, serializeEventType } from '../components/form';
import { NetworkEventTypeSettingsDocument } from '../queries/settings.generated';
import { Tabs } from './components';

export const EventTypeSettings = () => {
  const { currentNetwork, currentUser } = useAppContext();

  const navigate = useNavigate();

  const link = useLink();

  const params = useParams<{ eventTypeId: string }>();

  const eventTypeId = Number(params.eventTypeId);

  const { data, loading, error } = useQuery(NetworkEventTypeSettingsDocument, {
    variables: { networkId: currentNetwork.id, eventTypeId },
  });

  const [deleteEventType] = useDeleteEventType();
  const [updateEventType] = useUpdateEventType();

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const eventType = data?.network?.eventType;

  const initialValues = useMemo(
    () => ({
      color: eventType?.color ?? '#2B8ACB',
      contentStartTime: eventType?.contentStartTime ?? 'PT0S',
      contentStopTime: eventType?.contentStopTime ?? 'PT0S',
      description: eventType?.description ?? '',
      fcLeagueId: eventType?.fcLeagueId ?? -1,
      fcTeamId: eventType?.fcTeamId ?? -1,
      iconInputUri: '',
      iconUploadUri: '',
      manageDisplay: eventType?.manageDisplay ?? true,
      name: eventType?.name ?? '',
      sponsorStartTime: eventType?.sponsorStartTime ?? 'PT0S',
      sponsorStopTime: eventType?.sponsorStopTime ?? 'PT0S',
      syncFcAdmin: eventType?.syncFcAdmin ?? false,
    }),
    [eventType],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      const patch = serializeEventType(values);

      await updateEventType({ variables: { patch, id: eventTypeId } });

      formik.resetForm({ values });
    },
    validateOnMount: true,
    validationSchema: eventTypeSchema,
  });

  if ((!loading && !data) || error) return <Navigate replace to="/not-found" />;

  const saveButton = (
    <SaveButton
      disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
      type="submit"
      form="update-event-type"
    />
  );

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      <>
        <Toolbar
          actions={saveButton}
          breadcrumbsLabel={<RouterBreadcrumbs />}
          titleIcon={<Stadium />}
          titleText={data?.network?.eventType.name}
        />

        <Tabs current="Details" />

        {data && (
          <PageContainer>
            <EventTypeForm
              formik={formik}
              formId="update-event-type"
              iconFileUri={data.network?.eventType.iconFile?.uri}
              leagues={data.network?.leagues ?? []}
              newRecord={false}
            />

            {currentUser.admin && (
              <Timestamps
                sx={{ mt: 2 }}
                thing="Event Type"
                createdAt={data.network?.eventType.createdAt ?? ''}
                updatedAt={data.network?.eventType.updatedAt ?? ''}
              />
            )}

            <Box sx={{ mt: 2 }}>
              <SettingsHead>Remove Event Type</SettingsHead>
              <SettingsBody>
                <SettingsData className="last">
                  <SettingLabelWrapper>
                    <SettingName></SettingName>
                    <SettingDescription>
                      <span>
                        Deleting this event type will remove it from the system. &nbsp;&nbsp;
                        <b>This action is permanent.</b>
                      </span>
                    </SettingDescription>
                  </SettingLabelWrapper>
                  <SettingInputWrapper>
                    <Tooltip
                      title={
                        eventType?.canDestroy.value
                          ? 'Delete Event Type'
                          : eventType?.canDestroy.reasons?.fullMessages.join(', ') ||
                            'Cannot delete Event Type'
                      }
                    >
                      <span>
                        <DeleteButton
                          disabled={!eventType?.canDestroy.value}
                          onClick={async () => {
                            if (!(await confirmDelete())) return;
                            await deleteEventType({
                              variables: { id: eventTypeId },
                            });
                            navigate(link('/settings/event-types'), { replace: true });
                          }}
                        >
                          Delete Event Type
                        </DeleteButton>
                      </span>
                    </Tooltip>
                  </SettingInputWrapper>
                </SettingsData>
              </SettingsBody>
            </Box>
          </PageContainer>
        )}

        <ConfirmDialog
          {...confirmDeleteProps}
          confirm="Permanently Delete"
          deleteConfirm
          prompt={
            <span>
              Deleting this Event Type will permanently remove it from the system.{' '}
              <b>This operation may not be undone.</b>
            </span>
          }
          title="Delete Event Type"
        />
      </>
    </LoadingPane>
  );
};
