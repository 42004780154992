import { Box, type BoxProps } from '@mui/material';
import { DetailTextField } from '~/components/forms/details';
import {
  SettingDescription,
  SettingInputWrapper,
  SettingLabelWrapper,
  SettingName,
  SettingsBody,
  SettingsData,
  SettingsHead,
} from '~/components/settings';
import { formatDate } from '~/lib/datetime';

interface TimestampsProps extends BoxProps {
  thing: string;
  createdAt: string;
  updatedAt: string;
}

export const Timestamps = ({ thing, createdAt, updatedAt, ...boxProps }: TimestampsProps) => (
  <Box {...boxProps}>
    <SettingsHead>Timestamps</SettingsHead>
    <SettingsBody>
      <SettingsData>
        <SettingLabelWrapper>
          <SettingName>Created At</SettingName>
          <SettingDescription>When the {thing} was created</SettingDescription>
        </SettingLabelWrapper>
        <SettingInputWrapper>
          <DetailTextField disabled fullWidth value={formatDate(createdAt)} />
        </SettingInputWrapper>
      </SettingsData>
      <SettingsData className="last">
        <SettingLabelWrapper>
          <SettingName>Updated At</SettingName>
          <SettingDescription>When the {thing} was updated</SettingDescription>
        </SettingLabelWrapper>
        <SettingInputWrapper>
          <DetailTextField disabled fullWidth value={formatDate(updatedAt)} />
        </SettingInputWrapper>
      </SettingsData>
    </SettingsBody>
  </Box>
);
