import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { UpdateTvBrandDocument } from './update.generated';

export const useUpdateTVBrand = (options: ApiOptions<typeof UpdateTvBrandDocument> = {}) =>
  useMutation(UpdateTvBrandDocument, {
    ...useNotifications(UpdateTvBrandDocument, {
      failure: (error) =>
        error.message || 'An error occurred. Reload and try again or contact support.',
      success: 'Updated TV Brand',
    }),

    ...options,
  });
