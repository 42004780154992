import { Alert } from '@mui/material';
import { Box } from '@mui/system';
import type { useFormik } from 'formik';
import type { ComponentProps } from 'react';
import { object, string } from 'yup';
import { DetailTextField } from '~/components/forms/details';
import {
  SettingDescription,
  SettingFieldRequired,
  SettingInputWrapper,
  SettingLabelWrapper,
  SettingName,
  SettingsBody,
  SettingsData,
  SettingsHead,
  SettingsIntro,
} from '~/components/settings';

export interface TVBrandFormValues {
  baudRate?: string;
  mute?: string;
  name: string;
  powerOff?: string;
  powerOn?: string;
  unmute?: string;
  volumeDown?: string;
  volumeUp?: string;
}

export const validationSchema = object({
  baudRate: string()
    .optional()
    .label('Baud Rate')
    .test('is-valid-number', 'Baud Rate must be a valid positive integer', (value) => {
      if (!value) return true; // Allow empty value as optional
      const numberValue = Number(value);
      return Number.isInteger(numberValue) && numberValue >= 0 && numberValue <= 2147483647; // 32-bit signed integer max
    }),
  mute: string().optional().label('Mute'),
  name: string().required().label('Name'),
  powerOff: string().optional().label('Power Off'),
  powerOn: string().optional().label('Power On'),
  unmute: string().optional().label('Unmute'),
  volumeDown: string().optional().label('Volume Down'),
  volumeUp: string().optional().label('Volume Up'),
});

export interface TVBrandFormProps<T extends TVBrandFormValues>
  extends Omit<ComponentProps<'form'>, 'onSubmit'> {
  formik: ReturnType<typeof useFormik<T>>;
  formId: string;
  readonly?: boolean;
}

export const TVBrandForm = <T extends TVBrandFormValues>({
  formik,
  formId,
  readonly = false,
}: TVBrandFormProps<T>) => {
  return (
    <Box
      component="form"
      id={formId}
      onSubmit={formik.handleSubmit}
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <div>
        <SettingsHead>General</SettingsHead>
        <SettingsBody>
          <SettingsData className="last">
            <SettingLabelWrapper>
              <SettingName>
                Name<SettingFieldRequired>*</SettingFieldRequired>
              </SettingName>
              <SettingDescription>Name of the TV Brand</SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DetailTextField
                autoFocus
                disabled={readonly || formik.isSubmitting}
                error={formik.touched.name && !!formik.errors.name}
                fullWidth
                helperText={(formik.touched.name && formik.errors.name) || ' '}
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </SettingInputWrapper>
          </SettingsData>
        </SettingsBody>
      </div>
      <div>
        <SettingsHead>RS-232 Settings</SettingsHead>

        <SettingsIntro>
          <Alert severity="info">
            RS-232 commands are used to control TVs through a serial connection. Each command
            consists of a sequence of values, typically in hexadecimal format. You can also include
            constants such as <code>&lt;pause&gt;,&lt;cr&gt;,&lt;lf&gt;</code>.
          </Alert>
        </SettingsIntro>
        <SettingsBody>
          <SettingsData>
            <SettingLabelWrapper>
              <SettingName>Baud Rate</SettingName>
              <SettingDescription>Set the RS-232 communication speed</SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DetailTextField
                aria-label="baudRate"
                error={!!formik.errors.baudRate}
                disabled={readonly || formik.isSubmitting}
                fullWidth
                helperText={formik.errors.baudRate ?? ' '}
                name="baudRate"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.baudRate}
              />
            </SettingInputWrapper>
          </SettingsData>
          <SettingsData>
            <SettingLabelWrapper>
              <SettingName>Mute</SettingName>
              <SettingDescription>Hexadecimal value for Mute command</SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DetailTextField
                aria-label="mute"
                error={!!formik.errors.mute}
                disabled={readonly || formik.isSubmitting}
                fullWidth
                helperText={formik.errors.mute ?? ' '}
                name="mute"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.mute}
              />
            </SettingInputWrapper>
          </SettingsData>
          <SettingsData>
            <SettingLabelWrapper>
              <SettingName>Unmute</SettingName>
              <SettingDescription>Hexadecimal value for Unmute command</SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DetailTextField
                aria-label="unmute"
                error={!!formik.errors.unmute}
                disabled={readonly || formik.isSubmitting}
                fullWidth
                helperText={formik.errors.unmute ?? ' '}
                name="unmute"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.unmute}
              />
            </SettingInputWrapper>
          </SettingsData>
          <SettingsData>
            <SettingLabelWrapper>
              <SettingName>Power Off</SettingName>
              <SettingDescription>Hexadecimal value for Power Off command</SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DetailTextField
                aria-label="powerOff"
                error={!!formik.errors.powerOff}
                disabled={readonly || formik.isSubmitting}
                fullWidth
                helperText={formik.errors.powerOff ?? ' '}
                name="powerOff"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.powerOff}
              />
            </SettingInputWrapper>
          </SettingsData>
          <SettingsData>
            <SettingLabelWrapper>
              <SettingName>Power On</SettingName>
              <SettingDescription>Hexadecimal value for Power On command</SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DetailTextField
                aria-label="powerOn"
                error={!!formik.errors.powerOn}
                disabled={readonly || formik.isSubmitting}
                fullWidth
                helperText={formik.errors.powerOn ?? ' '}
                name="powerOn"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.powerOn}
              />
            </SettingInputWrapper>
          </SettingsData>
          <SettingsData>
            <SettingLabelWrapper>
              <SettingName>Volume Up</SettingName>
              <SettingDescription>Hexadecimal value for Volume Up command</SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DetailTextField
                aria-label="volumeUp"
                error={!!formik.errors.volumeUp}
                disabled={readonly || formik.isSubmitting}
                fullWidth
                helperText={formik.errors.volumeUp ?? ' '}
                name="volumeUp"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.volumeUp}
              />
            </SettingInputWrapper>
          </SettingsData>
          <SettingsData className="last">
            <SettingLabelWrapper>
              <SettingName>Volume Down</SettingName>
              <SettingDescription>Hexadecimal value for Volume Down command</SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DetailTextField
                aria-label="volumeDown"
                error={!!formik.errors.volumeDown}
                disabled={readonly || formik.isSubmitting}
                fullWidth
                helperText={formik.errors.volumeDown ?? ' '}
                name="volumeDown"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.volumeDown}
              />
            </SettingInputWrapper>
          </SettingsData>
        </SettingsBody>
      </div>
    </Box>
  );
};
