import { useFormik } from 'formik';
import { matchIsValidColor } from 'mui-color-input';
import { array, object, string } from 'yup';
import { ChannelEncryptionKind } from '~/generated/graphql';
import type { ChannelFormValues } from '../components/ChannelForm';

const isValidChannelUrl = (url: string) =>
  /^(udp|hdmi|https?|rtsp):\/\/[^\s/$.?#].[^\s]*$/i.test(url);

export const useChannelFormik = (
  initialValues: ChannelFormValues,
  onSubmit: (values: ChannelFormValues) => Promise<void>,
) => {
  const validationSchema = object({
    backgroundColor: string()
      .test((value) => value != null && matchIsValidColor(value))
      .default('#000000')
      .required()
      .label('Background color'),
    encryption: string().required().oneOf(Object.values(ChannelEncryptionKind)).label('Encryption'),
    name: string().required().label('Name'),
    number: string().required().label('Channel number'),
    thumbnailUri: string().required().label('Thumbnail'),
    url: string().required().label('URL'),
    urls: array()
      .min(1, 'At least one URL is required')
      .max(5, 'You can only have up to 5 URLs')
      .required('URLs are required')
      .test('all-valid-urls', 'All URLs must be valid', (value) => {
        const filteredUrls = value.filter((url: string) => url && url.trim() !== '');
        return filteredUrls.every(isValidChannelUrl);
      })
      .test('at-least-one-valid-url', 'At least one valid URL is required', (value) => {
        const filteredUrls = value.filter((url: string) => url && url.trim() !== '');
        return filteredUrls.some(isValidChannelUrl);
      })
      .test('unique-urls', 'URLs must be unique', (value) => {
        const filteredUrls = value.filter((url: string) => url && url.trim() !== '');
        const uniqueUrls = new Set(filteredUrls);
        return uniqueUrls.size === filteredUrls.length;
      }),
  });

  const handleSubmit = async (values: ChannelFormValues) => {
    await onSubmit(validationSchema.cast(values));
  };

  return useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: validationSchema,
  });
};
