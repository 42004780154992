import { useQuery } from '@apollo/client';
import { Tv } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router';
import { useDeleteTVBrand } from '~/api/tv-brands/delete';
import { useUpdateTVBrand } from '~/api/tv-brands/update';
import { DeleteButton, SaveButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import {
  SettingDescription,
  SettingInputWrapper,
  SettingLabelWrapper,
  SettingName,
  SettingsBody,
  SettingsData,
  SettingsHead,
} from '~/components/settings';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useLink } from '~/hooks/link';
import { Timestamps } from '../../components/timestamps';
import { NetworkTvBrandSettingsDocument } from '../queries/settings.generated';
import { Tabs, TVBrandForm, validationSchema } from './components';

export const TVBrandSettings = () => {
  const { currentNetwork, currentUser } = useAppContext();

  const navigate = useNavigate();

  const link = useLink();

  const params = useParams<{ tvBrandId: string }>();

  const tvBrandId = Number(params.tvBrandId);

  const { data, loading, error } = useQuery(NetworkTvBrandSettingsDocument, {
    variables: { networkId: currentNetwork.id, tvBrandId },
  });

  const [deleteTvBrand] = useDeleteTVBrand();
  const [updateTvBrand] = useUpdateTVBrand();

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const tvBrand = data?.network?.tvBrand;

  const initialValues = useMemo(
    () => ({
      baudRate: tvBrand?.baudRate.toString() ?? '',
      mute: tvBrand?.mute ?? '',
      name: tvBrand?.name ?? '',
      powerOff: tvBrand?.powerOff ?? '',
      powerOn: tvBrand?.powerOn ?? '',
      unmute: tvBrand?.unmute ?? '',
      volumeDown: tvBrand?.volumeDown ?? '',
      volumeUp: tvBrand?.volumeUp ?? '',
    }),
    [tvBrand],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      const newValues = validationSchema.cast(values);
      await updateTvBrand({
        variables: { patch: { ...newValues, baudRate: Number(newValues.baudRate) }, id: tvBrandId },
      });
    },
    validateOnMount: true,
    validationSchema,
  });

  if ((!loading && !data) || error) return <Navigate replace to="/not-found" />;

  if (!tvBrand) return null;

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      <>
        <Toolbar
          actions={
            <SaveButton
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
              type="submit"
              form="tv-brand-form"
            />
          }
          breadcrumbsLabel={<RouterBreadcrumbs />}
          titleIcon={<Tv />}
          titleText={tvBrand.name}
        />

        <Tabs current="Details" />

        <PageContainer>
          <TVBrandForm formik={formik} formId="tv-brand-form" readonly={!tvBrand.canUpdate.value} />

          {currentUser.admin && (
            <Timestamps
              sx={{ mt: 2 }}
              thing="TV Brand"
              createdAt={tvBrand.createdAt}
              updatedAt={tvBrand.updatedAt}
            />
          )}

          <Box sx={{ mt: 2 }}>
            <SettingsHead>Remove TV Brand</SettingsHead>
            <SettingsBody>
              <SettingsData className="last">
                <SettingLabelWrapper>
                  <SettingName></SettingName>
                  <SettingDescription>
                    <span>
                      Deleting this TV Brand will remove it from the system. &nbsp;&nbsp;
                      <b>This action is permanent.</b>
                    </span>
                  </SettingDescription>
                </SettingLabelWrapper>
                <SettingInputWrapper>
                  <Tooltip
                    title={
                      tvBrand.canDestroy.value
                        ? 'Delete TV Brand '
                        : tvBrand.canDestroy.reasons?.fullMessages.join(', ') ||
                          'Cannot Delete TV Brand'
                    }
                  >
                    <span>
                      <DeleteButton
                        disabled={!tvBrand.canDestroy.value}
                        onClick={async () => {
                          if (!(await confirmDelete())) return;
                          await deleteTvBrand({
                            variables: { id: tvBrandId },
                          });
                          navigate(link('/settings/tv-brands'), { replace: true });
                        }}
                      >
                        Delete TV Brand
                      </DeleteButton>
                    </span>
                  </Tooltip>
                </SettingInputWrapper>
              </SettingsData>
            </SettingsBody>
          </Box>
        </PageContainer>
        <ConfirmDialog
          {...confirmDeleteProps}
          confirm="Permanently Delete"
          deleteConfirm
          prompt={
            <span>
              Deleting this TV Brand will permanently remove it from the system.{' '}
              <b>This operation may not be undone.</b>
            </span>
          }
          title="Delete TV Brand"
        />
      </>
    </LoadingPane>
  );
};
