import { Box, Tooltip, Typography } from '@mui/material';
import { AuthorizedLink } from '~/components/links';
import { titleize } from '~/lib/string';
import type { ActiveShowName__ActiveShow as ActiveShow } from './ActiveShowName.generated';

/* GraphQL */ `#graphql
  fragment ActiveShowName__ActiveShow on ActiveShow {
    show {
      canUpdate {
        ...AuthorizationResult
      }
      id
      name
    }
    reason
    description
  }
`;

export const ActiveShowName = ({ activeShow }: { activeShow: ActiveShow | null | undefined }) => {
  if (!activeShow) return <>--</>;
  return (
    <Tooltip title={activeShow.description}>
      <Box display="flex" alignItems="center">
        <AuthorizedLink authorized={activeShow.show.canUpdate.value} entity={activeShow.show} />
        {activeShow.reason && (
          <Typography ml={0.5}>{`(${titleize(activeShow.reason)})`}</Typography>
        )}
      </Box>
    </Tooltip>
  );
};
