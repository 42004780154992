import { Edit, Info, MoreHoriz } from '@mui/icons-material';
import { Chip, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { ActionButtonLink } from '~/components/button';
import { formatDate } from '~/lib/datetime';
import type { NetworkTvBrands__TvBrand as TVBrand } from '../../queries/list.generated';
import { Menu } from '../components';

export const useColumns = () => {
  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  return useMemo<GridColDef<TVBrand>[]>(
    () => [
      {
        field: 'name',
        flex: 1,
        headerName: 'Name',
        minWidth: isSmallAndUp ? 180 : 0,
      },
      {
        field: 'kind',
        headerName: '',
        sortable: false,
        renderCell: ({ row }) => {
          return (
            !row.canUpdate.value && (
              <Tooltip arrow title="System TV Brands are readonly TV Brands included by FanConnect">
                <Chip label="System" color="info" />
              </Tooltip>
            )
          );
        },
        minWidth: 150,
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        renderCell: ({ row }) => {
          return formatDate(row.createdAt);
        },
        minWidth: 250,
      },
      {
        field: 'devices',
        headerName: 'Devices',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: ({ row }) => {
          return <Chip color="primary" label={row.deviceCount || 0} />;
        },
        minWidth: 100,
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: isSmallAndUp ? 150 : 0,
        getActions: ({ row }) => {
          const canUpdate = row.canUpdate.value;
          return [
            <ActionButtonLink
              key={`view-edit-${row.id}`}
              title={`${canUpdate ? 'Edit' : 'View'} TV Brand`}
              Icon={canUpdate ? Edit : Info}
              to={`${row.id}`}
            />,
            <Menu key={`more-action-${row.id}`} tvBrand={row} Icon={MoreHoriz} />,
          ];
        },
      },
    ],
    [isSmallAndUp],
  );
};
