import { Box, Typography } from '@mui/material';
import type { PasswordScore, PasswordStrength } from '~/lib/password';

const barColors = ['#d32f2f', '#ed6c02', '#ed6c02', '#2e7d32', '#2e7d32'];

interface StrengthBarProps {
  barNum: number;
  score: PasswordScore;
}

export const StrengthBar = ({ barNum, score }: StrengthBarProps) => {
  return (
    <Box
      display="flex"
      flexBasis="0"
      flexGrow="1"
      width="100%"
      maxWidth="100%"
      height="8px"
      borderRadius="20px"
      sx={{
        backgroundColor: score >= barNum ? barColors[score] : '#EBEBEB',
      }}
    ></Box>
  );
};

export const PasswordStrengthMeter = ({ strength }: { strength: PasswordStrength | undefined }) => {
  if (!strength) return null;
  const { feedback, score } = strength;
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" gap="4px">
        <StrengthBar barNum={0} score={score} />
        <StrengthBar barNum={1} score={score} />
        <StrengthBar barNum={2} score={score} />
        <StrengthBar barNum={3} score={score} />
        <StrengthBar barNum={4} score={score} />
      </Box>
      <Typography
        component="p"
        variant="caption"
        margin="5px 0 0"
        color="#898792"
        textAlign="right"
      >
        {feedback}
      </Typography>
    </Box>
  );
};
