import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DeviceKind, DeviceStatus } from '~/generated/graphql';
import { z } from 'zod';

const ARCH_SCHEMA = z.array(z.enum(['arm', 'arm64', 'x64']));
const DEMO_SCHEMA = z.array(z.boolean());
const DISPLAY_ON_SCHEMA = z.array(z.boolean());
const GROUP_SCHEMA = z.array(z.number());
const INTERNAL_SCHEMA = z.array(z.boolean());
const KIND_SCHEMA = z.array(
  z.enum([
    DeviceKind.Android,
    DeviceKind.Brightsign,
    DeviceKind.Linux,
    DeviceKind.Samsung,
    DeviceKind.Webos,
  ]),
);
const SILENCE_SCHEMA = z.array(z.boolean());
const STATUS_SCHEMA = z.array(
  z.enum([DeviceStatus.Healthy, DeviceStatus.Error, DeviceStatus.Warn]),
);
const TAG_SCHEMA = z.array(z.string());

export const useDeviceFiltersParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const tryParse = useCallback(
    <T>(func: () => T, key: string): T => {
      try {
        return func();
      } catch (e) {
        console.error(e);
        setTimeout(() => {
          setSearchParams((params) => {
            params.delete(key);
            return params;
          });
        }, 100);
        return [] as T;
      }
    },
    [setSearchParams],
  );

  return {
    arch: tryParse(() => ARCH_SCHEMA.parse(JSON.parse(searchParams.get('arch') || '[]')), 'arch'),
    demo: tryParse(() => DEMO_SCHEMA.parse(JSON.parse(searchParams.get('demo') || '[]')), 'demo'),
    displayOn: tryParse(
      () => DISPLAY_ON_SCHEMA.parse(JSON.parse(searchParams.get('displayOn') || '[]')),
      'displayOn',
    ),
    groups: tryParse(
      () => GROUP_SCHEMA.parse(JSON.parse(searchParams.get('groups') || '[]')),
      'groups',
    ),
    internal: tryParse(
      () => INTERNAL_SCHEMA.parse(JSON.parse(searchParams.get('internal') || '[]')),
      'internal',
    ),
    kind: tryParse(() => KIND_SCHEMA.parse(JSON.parse(searchParams.get('kind') || '[]')), 'kind'),
    silence: tryParse(
      () => SILENCE_SCHEMA.parse(JSON.parse(searchParams.get('silence') || '[]')),
      'silence',
    ),
    status: tryParse(
      () => STATUS_SCHEMA.parse(JSON.parse(searchParams.get('status') || '[]')),
      'status',
    ),
    tags: tryParse(() => TAG_SCHEMA.parse(JSON.parse(searchParams.get('tags') || '[]')), 'tags'),
  };
};
