import { Cancel } from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';
import { ClearAllButton } from './styles';

interface FilterFooterProps {
  handleClear: () => void;
}

export const FilterFooter = ({ handleClear }: FilterFooterProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '65px',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        padding: (theme) => theme.spacing(0, 2),
        backgroundColor: (theme) => theme.palette.common.white,
        [theme.breakpoints.down('sm')]: {
          height: '50px',
        },
      }}
    >
      <ClearAllButton onClick={handleClear} startIcon={<Cancel />}>
        Clear All
      </ClearAllButton>
    </Box>
  );
};
