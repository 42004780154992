import { useQuery } from '@apollo/client';
import { AddCircle, Tv } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { ButtonLink } from '~/components/link';
import { PageContainer } from '~/components/page-layout';
import { SearchContainer } from '~/components/search';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { NetworkTvBrandsDocument } from '../queries/list.generated';
import { Table } from './components';

export const TVBrandList = () => {
  const { currentNetwork } = useAppContext();

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const { data, loading } = useQuery(NetworkTvBrandsDocument, {
    variables: { networkId: currentNetwork.id, search },
  });

  const tvBrands = data?.network?.tvBrands;

  const newButton = (
    <ButtonLink color="primary" startIcon={<AddCircle />} variant="contained" to="new">
      New TV Brand
    </ButtonLink>
  );

  return (
    <>
      <Toolbar
        actions={newButton}
        breadcrumbsLabel={<RouterBreadcrumbs />}
        titleIcon={<Tv />}
        titleText="TV Brands"
      />
      <PageContainer>
        {tvBrands && (
          <>
            <SearchContainer>
              <SearchBar placeholder="Search TV Brands" search={search} />
              <Count selectedCount={0} totalCount={tvBrands.length} thing="tv brand" />
            </SearchContainer>
            <Table tvBrands={tvBrands} loading={loading} />
          </>
        )}
      </PageContainer>
    </>
  );
};
