import { DeviceKind, DeviceStatus } from '~/generated/graphql';
import type { useDeviceFiltersParams, DeviceFiltersState } from './';

export const INITIAL_ARCH = [
  { label: 'arm', selected: false, value: 'arm' as const },
  { label: 'arm64', selected: false, value: 'arm64' as const },
  { label: 'x64', selected: false, value: 'x64' as const },
];

export const INITIAL_DEMO = [
  { label: 'True', selected: false, value: true },
  { label: 'False', selected: false, value: false },
];

export const INITIAL_DISPLAY_ON = [
  { label: 'On', selected: false, value: true },
  { label: 'Off', selected: false, value: false },
];

export const INITIAL_INTERNAL = [
  { label: 'True', selected: false, value: true },
  { label: 'False', selected: false, value: false },
];

export const INITIAL_KIND = [
  { label: 'Android', selected: false, value: DeviceKind.Android },
  { label: 'Brightsign', selected: false, value: DeviceKind.Brightsign },
  { label: 'Linux', selected: false, value: DeviceKind.Linux },
  { label: 'Samsung', selected: false, value: DeviceKind.Samsung },
  { label: 'webOS', selected: false, value: DeviceKind.Webos },
];

export const INITIAL_SILENCE = [
  { label: 'True', selected: false, value: true },
  { label: 'False', selected: false, value: false },
];

export const INITIAL_STATUS = [
  { label: 'Online', selected: false, value: DeviceStatus.Healthy },
  { label: 'Offline', selected: false, value: DeviceStatus.Error },
  { label: 'Warning', selected: false, value: DeviceStatus.Warn },
];

export const allUnselectedState: DeviceFiltersState = {
  arch: INITIAL_ARCH,
  demo: INITIAL_DEMO,
  displayOn: INITIAL_DISPLAY_ON,
  groups: [],
  internal: INITIAL_INTERNAL,
  kind: INITIAL_KIND,
  silence: INITIAL_SILENCE,
  status: INITIAL_STATUS,
  tags: [],
};

export const useDeviceFiltersInitialState = (
  params: ReturnType<typeof useDeviceFiltersParams>,
) => ({
  arch: INITIAL_ARCH.map((x) => ({ ...x, selected: params.arch.includes(x.value) })),
  demo: INITIAL_DEMO.map((x) => ({ ...x, selected: params.demo.includes(x.value) })),
  displayOn: INITIAL_DISPLAY_ON.map((x) => ({
    ...x,
    selected: params.displayOn.includes(x.value),
  })),
  groups: [],
  internal: INITIAL_INTERNAL.map((x) => ({
    ...x,
    selected: params.internal.includes(x.value),
  })),
  kind: INITIAL_KIND.map((x) => ({ ...x, selected: params.kind.includes(x.value) })),
  silence: INITIAL_SILENCE.map((x) => ({ ...x, selected: params.silence.includes(x.value) })),
  status: INITIAL_STATUS.map((x) => ({ ...x, selected: params.status.includes(x.value) })),
  tags: [],
});
