import { useMediaQuery, useTheme } from '@mui/material';
import { type GridColumnVisibilityModel, type GridRowParams } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SeparatedDataGrid } from '~/components/data-grid';
import { EmptyState } from '~/components/empty-state';
import TVBrandIllustration from '~/images/illustrations/tv-brands.svg';
import { type NetworkTvBrands__TvBrand as TVBrand } from '../../queries/list.generated';
import { useColumns } from '../lib/columns';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={TVBrandIllustration}
    description="Create TV Brands for your Network."
    header="Create a TV Brand"
  />
);

export interface TableProps {
  tvBrands: readonly TVBrand[];
  loading: boolean;
}

export const Table = ({ tvBrands, loading }: TableProps) => {
  const columns = useColumns();
  const [tvBrandColumns, setColumns] = useState<GridColumnVisibilityModel>({});
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setColumns((x) => ({
      ...x,
      createdAt: !isSmallAndDown,
      devices: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  return (
    <SeparatedDataGrid
      checkboxSelection={false}
      columns={columns}
      columnVisibilityModel={tvBrandColumns}
      disableRowSelectionOnClick
      loading={loading}
      onColumnVisibilityModelChange={setColumns}
      onRowClick={({ row }: GridRowParams<TVBrand>) => navigate(String(row.id))}
      rows={tvBrands}
      slots={{
        columnResizeIcon: () => null,
        noRowsOverlay: NoRowsOverlay,
      }}
    />
  );
};
