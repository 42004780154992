import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Container, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { PasswordField } from '~/components/forms/password-field';
import { ButtonLink } from '~/components/link';
import { PasswordStrengthMeterBox } from '~/components/PasswordStrengthMeterBox';
import { CreatePasswordDocument, SetPasswordDocument } from '~/generated/graphql';
import logo from '~/images/fc-logo.svg';
import { MIN_PASSWORD_STRENGTH, usePasswordStrength } from '~/lib/password';

const useStyles = makeStyles((theme) => ({
  container: {},
  email: {
    display: 'none',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  input: {
    margin: theme.spacing(1.5, 0),
  },
  logo: {
    display: 'flex',
    flex: '1 1 40%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '75%',
    padding: theme.spacing(0, 0, 1),
  },
  main: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  message: {
    textTransform: 'uppercase',
    color: '#333333',
    padding: theme.spacing(0, 0, 2),
  },
  paper: {
    margin: theme.spacing(0, 2),
    padding: theme.spacing(6),
    border: '1px solid #E6E6E6',
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export const CreatePassword = () => {
  const classes = useStyles();

  const { token = '' } = useParams<{ token: string }>();

  const [newPassword, setNewPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');

  const { data } = useQuery(CreatePasswordDocument, { variables: { token } });
  const [setPassword, setPasswordState] = useMutation(SetPasswordDocument, {
    fetchPolicy: 'no-cache',
  });

  const passwordStrength = usePasswordStrength(newPassword);

  if (data?.currentUser != null) return <Navigate replace to="/" />;

  const success = setPasswordState.data?.setPassword?.success ?? false;

  return (
    <div className={classes.root}>
      <Container maxWidth="sm" className={classes.container}>
        <Paper className={classes.paper} elevation={5}>
          <div className={classes.logo}>
            <img alt="FanConnect" src={logo} />
          </div>
          {!success || setPasswordState.loading ? (
            <>
              <Typography className={classes.message} component="h1" variant="h5">
                Create Password
              </Typography>
              <Typography align="center" color="error">
                {setPasswordState.error && (
                  <p>Password Creation Failed: {setPasswordState.error.message}</p>
                )}
              </Typography>
              <form
                className={classes.form}
                onSubmit={async (event) => {
                  event.preventDefault();
                  await setPassword({
                    variables: { password: newPassword, token },
                  }).catch((_) => {
                    // TODO: Do something appropriate here
                    alert('failed');
                  });
                }}
              >
                <input
                  className={classes.email}
                  type="text"
                  autoComplete="username"
                  value={data?.emailByToken ?? undefined}
                  readOnly
                />
                <PasswordField
                  kind="new"
                  label="New Password"
                  autoFocus
                  fullWidth
                  value={newPassword}
                  className={classes.input}
                  disabled={setPasswordState.loading}
                  onChange={(event) => {
                    setNewPassword(event.target.value);
                  }}
                />
                <PasswordStrengthMeterBox strength={passwordStrength} />
                <PasswordField
                  kind="new"
                  label="Confirm Password"
                  fullWidth
                  value={confirmation}
                  className={classes.input}
                  disabled={setPasswordState.loading}
                  onChange={(event) => {
                    setConfirmation(event.target.value);
                  }}
                />
                {confirmation !== '' && confirmation !== newPassword && (
                  <p>Passwords do not match</p>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="secondary"
                  size="large"
                  className={classes.input}
                  disabled={
                    !newPassword ||
                    !confirmation ||
                    (passwordStrength?.score ?? 0) < MIN_PASSWORD_STRENGTH ||
                    newPassword !== confirmation ||
                    setPasswordState.loading
                  }
                >
                  Set Password
                </Button>
              </form>
            </>
          ) : setPasswordState.error?.networkError || data?.emailByToken == null ? (
            <>
              <Typography variant="subtitle1" textAlign="center" marginY="8px" color="error">
                Something went wrong. Please check your network connection, reload the page, and try
                again.
              </Typography>
              <Button
                variant="contained"
                fullWidth
                color="secondary"
                size="large"
                className={classes.input}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reload Page
              </Button>
            </>
          ) : (
            <>
              <Typography variant="subtitle1" textAlign="center" marginY="8px">
                Your password has been successfully updated. You should receive an email confirming
                this change. You may now sign in to the application.
              </Typography>
              <ButtonLink
                variant="contained"
                color="secondary"
                size="large"
                className={classes.input}
                to="/login"
              >
                Sign In
              </ButtonLink>
            </>
          )}
        </Paper>
        <Box flex="1 1 40%" />
      </Container>
    </div>
  );
};
