import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { deepPurple, grey, lightBlue, orange, red } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  StyledEngineProvider,
  ThemeProvider,
  type Theme,
} from '@mui/material/styles';
import createPalette from '@mui/material/styles/createPalette';
import createTypography from '@mui/material/styles/createTypography';
import { useRef, type ReactNode } from 'react';
import { useNonce } from '~/hooks/csp';

declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    tertiary?: PaletteColorOptions;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const white = '#FFFFFF';

const black = '#000000';

// Toggle colors:
//   Active: dfeeff
//   Hover:  eff6ff

const primary = {
  contrastText: white,
  dark: '#1b3a61',
  main: '#2B8ACB',
  light: '#eff6ff',
  toggle: {
    active: '#dfeeff',
    hover: '#F9FAFA',
  },
} as const;

const secondary = {
  contrastText: white,
  dark: '#00325b',
  main: '#003F72',
  light: '#33658e',
} as const;

const palette = createPalette({
  common: {
    black,
    white,
  },
  primary,
  secondary,
  success: {
    contrastText: white,
    dark: '#00a000',
    main: '#11D300',
    light: '#68ff4d',
  },
  info: {
    contrastText: white,
    dark: lightBlue[900],
    main: lightBlue[700],
    light: lightBlue[500],
  },
  warning: {
    contrastText: white,
    dark: orange[900],
    main: '#ED6C02',
    light: orange[500],
  },
  error: {
    contrastText: white,
    dark: 'rgb(243, 47, 52)',
    main: 'rgb(245, 84, 89)',
    light: red[400],
  },
  tertiary: {
    contrastText: white,
    dark: deepPurple[900],
    main: deepPurple[500],
    light: deepPurple[200],
  },
  text: {
    primary: black,
    secondary: '#969696',
    // link: primary.main,
  },
  background: {
    // default: '#F8F8F8',
    default: '#F8F8F8',
    paper: white,
  },
  // icon: colors.blueGrey[600],
  divider: grey[200],
});

const typography = createTypography(palette, {
  fontFamily: 'Lato, Arial, Helvetica, sans-serif',
  h1: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '35px',
    lineHeight: '40px',
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '29px',
    lineHeight: '32px',
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '28px',
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: '16px',
    lineHeight: '25px',
  },
  subtitle2: {
    // color: palette.text.secondary,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
  },
  body1: {
    color: palette.text.primary,
    fontSize: '14px',
    lineHeight: '21px',
  },
  body2: {
    // color: palette.text.secondary,
    fontSize: '12px',
    lineHeight: '18px',
  },
  button: {
    color: palette.text.primary,
  },
  caption: {
    // color: palette.text.secondary,
    fontSize: '11px',
    lineHeight: '13px',
  },
  overline: {
    // color: palette.text.secondary,
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '13px',
    textTransform: 'uppercase' as const,
  },
});

const components = {
  MuiCssBaseline: {
    styleOverrides: {
      '*::-webkit-scrollbar': {
        width: '7px',
        height: '7px',
      },
      '*::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, .1)',
      },
      '*::-webkit-scrollbar-thumb': {
        background: 'rgba(0, 0, 0, .25)',
        borderRadius: '5px',
        transition: 'color .2s ease',
        cursor: 'pointer',
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        '&:before': {
          top: 0,
        },
        '&.Mui-expanded': {
          margin: 'unset',
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      content: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '14px',
        fontWeight: 500,
        gap: '8px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&.Mui-expanded': {
          margin: 'unset',
        },
      },
      root: {
        backgroundColor: palette.background.default,
        borderRadius: '4px',
        '&.Mui-expanded': {
          minHeight: '48px',
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '8px',
        fontSize: '1rem',
        padding: '16px',
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        fontSize: '1rem',
      },
    },
  },
  MuiAvatar: {
    defaultProps: {
      imgProps: { crossOrigin: 'anonymous' as const },
    },
    styleOverrides: {
      root: {
        fontSize: '1rem',
        fontWeight: 600,
        borderStyle: 'solid',
        borderWidth: 1,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: 600,
        textTransform: 'none',
      },
      outlined: {
        '&:hover': {
          backgroundColor: '#F9FAFA',
        },
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      outlinedPrimary: {
        color: black,
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#4096d0',
        },
      },
      containedSecondary: {
        fontWeight: 600,
      },
    },
  },
  MuiCardMedia: {
    styleOverrides: {
      img: {
        objectFit: 'contain',
      },
    },
  },
  MuiChip: {
    defaultProps: {
      size: 'small' as const,
    },
    styleOverrides: {
      root: {
        fontWeight: 600,
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        fontSize: '14px',
        borderColor: '#e6e6e6',
      },
      columnHeader: {
        height: '40px !important',
      },
      columnHeaderTitle: {
        fontWeight: 'bold',
      },
      editInputCell: {
        fontSize: '14px',
      },
      footerContainer: {
        marginTop: '4px',
        borderTop: '0',
      },
      withBorderColor: {
        borderColor: '#e8e8e8',
      },
      virtualScroller: {
        minHeight: '100px',
        overflow: 'auto',
      },
      pinnedColumns: {
        backgroundColor: '#fff',
      },
      pinnedColumnHeaders: {
        backgroundColor: '#fff',
        paddingRight: '0px !important',
      },
      skeletonLoadingOverlay: {
        height: 'auto',
      },
      cell: {
        display: 'flex',
        alignItems: 'center',
      },
      cellSkeleton: {
        height: '45px !important',
        minHeight: '45px !important',
        maxHeight: '45px !important',
      },
      row: {
        '&.MuiDataGrid-rowSkeleton': {
          marginTop: '0 !important',
        },
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        backgroundColor: '#F9FAFA',
        borderTop: '1px solid #e8e8e8',
        marginRight: 0,
        padding: '16px',
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        backgroundColor: '#F9FAFA',
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        minHeight: 0,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      contained: {
        marginLeft: 0,
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.03)',
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: palette.primary.main,
      },
    },
  },
  MuiList: {
    styleOverrides: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: palette.primary.dark,
        minWidth: 32,
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      primary: {
        fontWeight: 500,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&:hover, &.Mui-selected:hover, &.Mui-selected, &.Mui-selected.Mui-focusVisible': {
          backgroundColor: '#F9FAFA',
        },
      },
    },
  },
  MuiOutlinedInput: {
    defaultProps: {},
    styleOverrides: {
      root: {
        '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: palette.primary.main,
        },
        backgroundColor: '#ffffff',
        '&.Mui-disabled': {
          backgroundColor: '#F9FAFA',
        },
        '&.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: '#e8e8e8',
        },
      },
      input: {
        borderColor: '#e8e8e8',
      },
      notchedOutline: {
        borderColor: '#e8e8e8',
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        fontSize: '14px',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      elevation1: {
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
      },
    },
  },
  MuiSelect: {},
  MuiSlider: {
    styleOverrides: {
      root: {
        height: 5,
        padding: '15px 0',
        '& .MuiSlider-valueLabel': {
          fontSize: 14,
          fontWeight: 'normal',
          top: 0,
          backgroundColor: 'unset',
          color: palette.text.primary,
          '&::before': {
            display: 'none',
          },
          '& *': {
            background: 'transparent',
            color: '#000',
          },
        },
        '& .MuiSlider-rail': {
          opacity: 0.5,
          boxShadow: 'inset 0px 0px 4px -2px #000',
          backgroundColor: '#d0d0d0',
        },
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      lineVertical: {
        borderColor: 'transparent',
      },
      vertical: {
        height: 24,
      },
    },
  },
  MuiStepContent: {
    defaultProps: {
      TransitionProps: {
        in: true,
      },
    },
    styleOverrides: {
      root: {
        borderColor: 'transparent',
      },
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiTable: {
    defaultProps: {
      size: 'small' as const,
    },
    styleOverrides: {
      root: {
        backgroundColor: '#ffffff',
        border: '1px solid #e8e8e8',
        borderRadius: '6px',
        borderCollapse: 'separate',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        fontWeight: 600,
        borderBottom: 0,
      },
      root: {
        fontSize: 16,
        borderBottom: `1px solid #e8e8e8`,
        '&:first-of-type th, &:first-of-type td, &:last-child th, &:last-child td': {
          borderBottom: 0,
        },
      },
      sizeSmall: {
        padding: '8px 24px 6px 16px',
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
        border: '1px solid #e8e8e8',
      },
    },
  },
  MuiTablePagination: {
    defaultProps: {
      rowsPerPageOptions: [10, 25, 50],
      component: 'div' as const,
    },
    styleOverrides: {
      actions: {
        '& > button': {
          padding: 0,
        },
      },
      displayedRows: {
        fontSize: '14px',
      },
      selectLabel: {
        fontSize: '14px',
      },
      root: {
        minHeight: 36,
      },
      select: {
        paddingBottom: 4,
        fontSize: '14px',
        fontWeight: 600,
      },
      toolbar: {
        minHeight: 36,
        paddingRight: 16,
      },
    },
  },
  MuiTableRow: {
    defaultProps: {
      role: 'checkbox',
      tabIndex: -1,
    },
    styleOverrides: {
      root: {
        '&$selected': {
          backgroundColor: '#F9FAFA',
        },
        '&$hover': {
          '&:hover': {
            backgroundColor: '#F9FAFA',
          },
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontWeight: '700',
        letterSpacing: '0.1em',
        fontSize: '12px',
        minHeight: 36,
        minWidth: 75,
        '@media (min-width: 600px)': {
          minWidth: 75,
        },
        '&$selected': {
          color: palette.primary.main,
          fontWeight: 500,
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        borderBottom: '1px solid #e8e8e8',
        backgroundColor: '#ffffff',
        minHeight: 45,
        padding: '8px 48px 0',
      },

      fixed: {
        minHeight: 36,
      },
      indicator: {
        height: 3,
        backgroundColor: palette.primary.main,
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        '&.Mui-selected:not(.Mui-disabled)': {
          color: palette.common.white,
          '&, &:hover': {
            backgroundColor: palette.primary.main,
          },
        },
        '&&:hover': {
          backgroundColor: '#4096d0',
          color: palette.common.white,
        },
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      gutters: {
        '@media (min-width: 600px)': {
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: 14,
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      gutterBottom: {
        marginBottom: 8,
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      size: 'small' as const,
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
};

const theme: Theme = createMuiTheme({
  palette,
  components,
  spacing: 8,
  typography,

  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1280,
      xl: 1920,
    },
  },

  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

export interface ThemeProps {
  children: ReactNode;
}

export const ThemeWrapper = ({ children }: ThemeProps) => {
  const nonce = useNonce();
  const cache = useRef(createCache({ key: 'neo', nonce, prepend: true })).current;

  return (
    <StyledEngineProvider injectFirst>
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </CacheProvider>
    </StyledEngineProvider>
  );
};
