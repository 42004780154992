import type { DeviceKind, DeviceStatus } from '~/generated/graphql';
import {
  INITIAL_DEMO,
  INITIAL_DISPLAY_ON,
  INITIAL_INTERNAL,
  INITIAL_KIND,
  INITIAL_STATUS,
} from '../context';

export const SCREENSHOT_INTERVALS: { [key: string]: string } = {
  PT1M: '1 minute',
  PT2M: '2 minutes',
  PT5M: '5 minutes',
  PT10M: '10 minutes',
  PT15M: '15 minutes',
  PT30M: '30 minutes',
  PT60M: '60 minutes',
};

type FilterKind = Exclude<DeviceKind, 'WEB'>;

/**
 * Helper to generate a scheduled show name from specified device filters.
 *
 * Examples:
 *
 * Tags: Lexus Lounge, Even
 * Search: Lexus
 * Groups: Concessions
 * Status: Connected
 * Kind: Android
 *
 * If multiple filter options are used, they will be separated by --
 */
export const generateScheduledShowName = (
  availableDeviceGroups: { id: number; name: string }[],
  filters: {
    arch: string[];
    demo: boolean[];
    displayOn: boolean[];
    groups: number[];
    internal: boolean[];
    kind: FilterKind[];
    search: string;
    status: DeviceStatus[];
    tags: string[];
  },
) => {
  const { demo, displayOn, groups, internal, kind, search, status, tags } = filters;

  const generateString = (label: string, values: string[]) => {
    const formattedValues = values.filter((value) => value !== '').join(', ');
    return formattedValues !== '' ? `${label}: "${formattedValues}"` : '';
  };

  return [
    generateString(
      'Kind',
      kind.map((value) => (INITIAL_KIND.find((kind) => kind.value === value) || {}).label ?? ''),
    ),
    generateString('Search', [search]),
    generateString(
      'Groups',
      groups.map(
        (value) => (availableDeviceGroups.find((group) => group.id === value) || {}).name ?? '',
      ),
    ),
    generateString(
      'Status',
      status.map(
        (value) => (INITIAL_STATUS.find((status) => status.value === value) || {}).label ?? '',
      ),
    ),
    generateString('Tags', tags),
    generateString(
      'Display',
      displayOn.map(
        (value) =>
          (INITIAL_DISPLAY_ON.find((display) => display.value === value) || {}).label ?? '',
      ),
    ),
    generateString(
      'Internal',
      internal.map(
        (value) =>
          (INITIAL_INTERNAL.find((internal) => internal.value === value) || {}).label ?? '',
      ),
    ),
    generateString(
      'Demo',
      demo.map((value) => (INITIAL_DEMO.find((demo) => demo.value === value) || {}).label ?? ''),
    ),
  ]
    .filter(Boolean)
    .join(' -- ');
};
