import { useApolloClient } from '@apollo/client';
import { TinyColor } from '@ctrl/tinycolor';
import { Info } from '@mui/icons-material';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
  styled,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import type { FormikProps } from 'formik';
import { matchIsValidColor, MuiColorInput, type MuiColorInputProps } from 'mui-color-input';
import { DetailKey } from '~/components/info-pane';
import { DataItemSelect, DataSourceSelect, MediaSelect } from '~/components/inputs';
import { useAppContext } from '~/contexts';
import { TemplateParamKind } from '~/generated/graphql';
import { AppEditor__TemplateParam as TemplateParam } from '../gql';
import type { FormValues } from '../lib';
import { MediaMultiSelect, StringArrayField } from './';

const Row = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  justifyContent: 'center',
  position: 'relative',
}));

const OptionLabel = styled(DetailKey)({
  flexBasis: '40%',
  paddingBottom: 0,
});

const OptionValue = styled('dd')({
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '60%',
  minWidth: 0,
});

const RequiredTag = styled('span')(({ theme }) => ({
  color: theme.palette.error.dark,
}));

const InfoTooltip = styled('span')({});

const useCachedTemplateParam = (id: number): TemplateParam => {
  const client = useApolloClient();

  const templateParam = client.readFragment({
    id: client.cache.identify({ __typename: 'TemplateParam', id }),
    fragment: TemplateParam,
    fragmentName: 'AppEditor__TemplateParam',
  });

  if (templateParam == null) throw new Error('templateParam not in cache');

  return templateParam;
};

export interface OptionFieldProps {
  formik: FormikProps<FormValues>;
  templateParamId: number;
}

export const OptionField = ({ formik, templateParamId }: OptionFieldProps) => {
  const { currentNetwork } = useAppContext();
  const templateParam = useCachedTemplateParam(templateParamId);

  const { description, kind, label, nullable } = templateParam;

  const value = formik.values.contentOptions[templateParamId];
  const touched = !!formik.touched.contentOptions?.[templateParamId];
  const error = formik.errors.contentOptions?.[templateParamId];

  const disabled = formik.isSubmitting;

  const inputProps = {
    'aria-label': label,
    disabled,
    name: `contentOptions[${templateParamId}]`,
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    size: 'small' as const,
  };

  const errorProps = {
    error: touched && !!error,
    helperText: (touched && error) || '',
  };

  const textFieldProps = {
    margin: 'dense' as const,
    value: value ?? '',
    variant: 'outlined' as const,
  };

  const textAreaProps = {
    multiline: true,
    minRows: 4,
  };

  const renderSelectValue = (val: unknown) => {
    const selected = Array.isArray(val) ? val : [val];
    return selected
      .map((selection) => templateParam.options?.find((option) => option.value === selection)?.name)
      .join(', ');
  };

  const input = templateParam.options?.length ? (
    <FormControl fullWidth margin="dense">
      <Select
        {...inputProps}
        value={value ?? (templateParam.array ? [] : '')}
        multiple={templateParam.array}
        fullWidth
        renderValue={renderSelectValue}
      >
        {templateParam.options.map((item, index) => (
          <MenuItem key={index} value={item.value as never}>
            {templateParam.array && (
              <Checkbox checked={Array.isArray(value) && value.includes(item.value)} />
            )}
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={errorProps.error}>{errorProps.helperText}</FormHelperText>
    </FormControl>
  ) : kind === TemplateParamKind.Boolean ? (
    <FormControl fullWidth>
      <Switch {...inputProps} checked={!!(value ?? false)} color="primary" />
      <FormHelperText error={errorProps.error}>{errorProps.helperText}</FormHelperText>
    </FormControl>
  ) : templateParam.array && (kind === 'IMAGE' || kind === 'MEDIA' || kind === 'VIDEO') ? (
    <MediaMultiSelect
      aria-label={description}
      disabled={disabled}
      kind={kind === 'MEDIA' ? ['IMAGE', 'VIDEO'] : [kind]}
      onChange={(selectedIds) => {
        const ids = selectedIds.map((x) => Number(x.split('-').at(1)));
        void formik.setFieldValue(inputProps.name, ids, true);
      }}
      title="Select Media"
      value={Array.isArray(value) ? value.map((x) => `MediaItem-${x}`) : []}
    />
  ) : templateParam.array ? (
    <StringArrayField
      {...inputProps}
      {...errorProps}
      formik={formik}
      templateParam={templateParam}
      type={
        kind === TemplateParamKind.Float || kind === TemplateParamKind.Integer ? 'number' : 'text'
      }
      values={value ?? []}
    />
  ) : kind === TemplateParamKind.Float || kind === TemplateParamKind.Integer ? (
    <FormControl fullWidth>
      <TextField {...inputProps} {...textFieldProps} {...errorProps} type="number" />
    </FormControl>
  ) : kind === TemplateParamKind.Image ||
    kind === TemplateParamKind.Video ||
    kind === TemplateParamKind.Media ||
    kind === TemplateParamKind.Pdf ? (
    <MediaSelect
      aria-label={description}
      disabled={disabled}
      kind={kind}
      mediaItemId={typeof value === 'number' ? value : undefined}
      networkId={currentNetwork.id}
      onChange={(mediaItemId) => formik.setFieldValue(inputProps.name, mediaItemId, true)}
    />
  ) : kind === TemplateParamKind.Color ? (
    <FormControl fullWidth>
      <MuiColorInput
        {...(textFieldProps as MuiColorInputProps)}
        {...(errorProps as MuiColorInputProps)}
        format="hex"
        isAlphaHidden={true}
        InputProps={{
          ...inputProps,
          onBlur: (event) => {
            // On blur, update to valid CSS color string
            if (matchIsValidColor((value as string | null) ?? ''))
              void formik.setFieldValue(inputProps.name, new TinyColor(value as string).toString());
            inputProps.onBlur(event);
          },
        }}
        onChange={(color) => formik.setFieldValue(inputProps.name, color, true)}
      />
    </FormControl>
  ) : kind === TemplateParamKind.Data ? (
    <DataItemSelect
      aria-label={description}
      dataItemId={typeof value === 'number' ? value : undefined}
      disabled={disabled}
      onChange={(dataItemId) => formik.setFieldValue(inputProps.name, dataItemId, true)}
      optionName={templateParam.label}
    />
  ) : kind === TemplateParamKind.DataSource ? (
    <DataSourceSelect
      {...errorProps}
      {...inputProps}
      nullable={nullable}
      value={value ? String(value) : ''}
    />
  ) : (
    // Fall back to string
    // kind === TemplateParamKind.String || kind === TemplateParamKind.Text ?
    <FormControl fullWidth>
      <TextField
        {...inputProps}
        {...textFieldProps}
        {...errorProps}
        {...(kind === TemplateParamKind.Text && textAreaProps)}
      />
    </FormControl>
  );

  return (
    <Row>
      <Tooltip title={description}>
        <InfoTooltip>
          <Info color="primary" fontSize="small" sx={{ verticalAlign: 'bottom' }} />
        </InfoTooltip>
      </Tooltip>
      <OptionLabel>
        <Typography variant="subtitle1" sx={{ fontSize: '14px' }}>
          {label}
          {!nullable && <RequiredTag> *</RequiredTag>}{' '}
        </Typography>
      </OptionLabel>
      <OptionValue>{input}</OptionValue>
    </Row>
  );
};
