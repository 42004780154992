import type * as Types from '~/generated/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateTvBrandVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  patch: Types.TvBrandPatch;
}>;


export type UpdateTvBrand = { readonly __typename: 'Mutation', readonly updateTvBrand?: { readonly __typename: 'UpdateTVBrandPayload', readonly tvBrand: { readonly __typename: 'TVBrand', readonly baudRate: number, readonly id: number, readonly name: string, readonly mute: string, readonly powerOff: string, readonly powerOn: string, readonly unmute: string, readonly updatedAt: string, readonly volumeDown: string, readonly volumeUp: string } } | null };


export const UpdateTvBrandDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateTVBrand"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"patch"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TvBrandPatch"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateTvBrand"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"patch"},"value":{"kind":"Variable","name":{"kind":"Name","value":"patch"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"tvBrand"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"baudRate"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"mute"}},{"kind":"Field","name":{"kind":"Name","value":"powerOff"}},{"kind":"Field","name":{"kind":"Name","value":"powerOn"}},{"kind":"Field","name":{"kind":"Name","value":"unmute"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"volumeDown"}},{"kind":"Field","name":{"kind":"Name","value":"volumeUp"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateTvBrand, UpdateTvBrandVariables>;