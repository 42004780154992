import {
  DesktopWindows,
  DeveloperBoard,
  DoNotStep,
  Hub,
  RocketLaunch,
  Router,
  Sell,
  VisibilityOff,
  Wifi,
} from '@mui/icons-material';
import {
  FilterAccordionContainer,
  FilterCollapsibleList,
  FilterContainer,
  FilterFooter,
  FilterHeader,
} from '~/components/Filter';
import { useAppContext } from '~/contexts';
import { useDeviceFilters } from '../context';

export const FilterAccordion = () => {
  const { currentUser } = useAppContext();
  const { clearFilters, setFilter, ...filters } = useDeviceFilters();

  return (
    <FilterContainer>
      <FilterHeader />
      <FilterAccordionContainer>
        {filters.tags.length > 0 && (
          <FilterCollapsibleList
            Icon={Sell}
            items={filters.tags}
            label="Tags"
            onClick={(_, value) => setFilter({ field: 'tags', value })}
          />
        )}
        {filters.groups.length > 0 && (
          <FilterCollapsibleList
            Icon={Hub}
            items={filters.groups}
            label="Groups"
            onClick={(_, value) => setFilter({ field: 'groups', value })}
          />
        )}
        <FilterCollapsibleList
          Icon={Router}
          items={filters.kind}
          label="Type"
          onClick={(_, value) => setFilter({ field: 'kind', value })}
        />
        {currentUser.admin && (
          <FilterCollapsibleList
            Icon={DeveloperBoard}
            items={filters.arch}
            label="Arch"
            onClick={(_, value) => setFilter({ field: 'arch', value })}
          />
        )}
        <FilterCollapsibleList
          Icon={Wifi}
          items={filters.status}
          label="Status"
          onClick={(_, value) => setFilter({ field: 'status', value })}
        />
        <FilterCollapsibleList
          Icon={DesktopWindows}
          items={filters.displayOn}
          label="Display"
          onClick={(_, value) => setFilter({ field: 'displayOn', value })}
        />
        <FilterCollapsibleList
          Icon={DoNotStep}
          items={filters.silence}
          label="Silenced"
          onClick={(_, value) => setFilter({ field: 'silence', value })}
        />
        {currentUser.admin && (
          <>
            <FilterCollapsibleList
              Icon={VisibilityOff}
              items={filters.internal}
              label="Internal"
              onClick={(_, value) => setFilter({ field: 'internal', value })}
            />
            <FilterCollapsibleList
              Icon={RocketLaunch}
              items={filters.demo}
              label="Demo"
              onClick={(_, value) => setFilter({ field: 'demo', value })}
            />
          </>
        )}
      </FilterAccordionContainer>
      <FilterFooter handleClear={clearFilters} />
    </FilterContainer>
  );
};
