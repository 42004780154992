import { DesktopWindows, DesktopWindowsOutlined, LiveTv } from '@mui/icons-material';
import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { DeviceStatus } from '~/components/devices';
import { pluralize } from '~/lib/string';
import type { DeviceIndicators__Device as Device } from './device-indicators.generated';

/* GraphQL */ `#graphql
  fragment DeviceIndicators__Device on Device {
    activationChangedAt
    active
    activeShow {
      show {
        id
        channelGuide {
          id
          channelCount
        } 
      }
      }
    displayOn
    id
    timeSinceUpdate {
      ...Interval
    }
  }
`;

interface DeviceDisplayIndicatorProps {
  device: Device;
}

export const DeviceDisplayIndicator = ({ device }: DeviceDisplayIndicatorProps) => (
  <Tooltip
    title={
      device.displayOn === true
        ? 'Display is on'
        : device.displayOn === false
        ? 'Display is off'
        : 'Unknown display status'
    }
    arrow
  >
    {device.displayOn === true ? (
      <DesktopWindowsOutlined sx={{ color: 'success.main' }} />
    ) : device.displayOn === false ? (
      <DesktopWindows sx={{ color: 'primary.dark' }} />
    ) : (
      <Typography fontSize={16}>N/A</Typography>
    )}
  </Tooltip>
);

interface DeviceChannelIndicatorProps {
  device: Device;
}

export const DeviceChannelIndicator = ({ device }: DeviceChannelIndicatorProps) => {
  const theme = useTheme();
  const channelCount = device.activeShow?.show.channelGuide?.channelCount ?? 0;

  return (
    <Tooltip
      title={
        channelCount > 0
          ? `${channelCount} IPTV ${pluralize('channel', channelCount)} available`
          : 'No IPTV channels available'
      }
      arrow
    >
      <LiveTv sx={{ color: channelCount > 0 ? 'success.main' : theme.palette.grey[300] }} />
    </Tooltip>
  );
};

export interface DeviceIndicatorsProps {
  device: Device;
}

export const DeviceIndicators = ({ device }: DeviceIndicatorsProps) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
      <DeviceStatus device={device} />
      <DeviceDisplayIndicator device={device} />
      <DeviceChannelIndicator device={device} />
    </Box>
  );
};
