import { useCallback, useEffect, useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { DeviceGroupNew as DeviceGroup, DeviceKind, DeviceStatus } from '~/generated/graphql';
import { allUnselectedState, useDeviceFiltersInitialState, useDeviceFiltersParams } from './';

export interface DeviceFilter<T> {
  label: string;
  selected: boolean;
  value: T;
}

export interface DeviceFiltersState {
  arch: DeviceFilter<'arm' | 'arm64' | 'x64'>[];
  demo: DeviceFilter<boolean>[];
  displayOn: DeviceFilter<boolean>[];
  groups: DeviceFilter<number>[];
  internal: DeviceFilter<boolean>[];
  kind: DeviceFilter<DeviceKind>[];
  silence: DeviceFilter<boolean>[];
  status: DeviceFilter<DeviceStatus>[];
  tags: DeviceFilter<string>[];
}

export type DeviceFiltersAction =
  | { type: 'CLEAR_FILTERS' }
  | ({ type: 'SET_FILTER' } & {
      [K in keyof DeviceFiltersState]: { field: K; value: DeviceFiltersState[K] };
    }[keyof DeviceFiltersState]);

export const useDeviceFiltersReducer = (
  deviceTags: readonly string[] | undefined,
  deviceGroups: ReadonlyArray<Pick<DeviceGroup, 'id' | 'name'>> | undefined,
) => {
  const [_, setSearchParams] = useSearchParams();
  const params = useDeviceFiltersParams();
  const initialState = useDeviceFiltersInitialState(params);

  const reducer = useCallback(
    (state: DeviceFiltersState, action: DeviceFiltersAction) => {
      switch (action.type) {
        case 'CLEAR_FILTERS': {
          setSearchParams((params) => {
            Object.keys(state).forEach((x) => params.delete(x));
            params.set('page', '1');
            return params;
          });

          return allUnselectedState;
        }

        case 'SET_FILTER': {
          const {
            field,
            value,
          }: { field: string; value: DeviceFilter<boolean | string | number>[] } = action;
          const selected = value.filter((x) => x.selected);
          setSearchParams((params) => {
            selected.length > 0
              ? params.set(field, JSON.stringify(selected.map((x) => x.value)))
              : params.delete(field);
            params.set('page', '1'); // reset pagination when filters change
            return params;
          });
          return {
            ...state,
            [field]: value,
          };
        }

        default: {
          return state;
        }
      }
    },
    [setSearchParams],
  );

  const [filters, filtersDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!deviceTags) return;
    if (deviceTags.join() === filters.tags.map((x) => x.value).join()) return;
    filtersDispatch({
      type: 'SET_FILTER',
      field: 'tags',
      value: deviceTags.map((x) => ({
        label: x,
        selected: params.tags.includes(x),
        value: x,
      })),
    });
  }, [deviceTags, filters.tags, params.tags]);

  useEffect(() => {
    if (!deviceGroups) return;
    if (deviceGroups.map((x) => x.id).join() === filters.groups.map((x) => x.value).join()) return;
    filtersDispatch({
      type: 'SET_FILTER',
      field: 'groups',
      value: deviceGroups.map((x) => ({
        label: x.name,
        selected: params.groups.includes(x.id),
        value: x.id,
      })),
    });
  }, [deviceGroups, filters.groups, params.groups]);

  return [filters, filtersDispatch] as const;
};
