import { Box, Container as ContainerBase, styled } from '@mui/material';
import type { ReactChildren, ReactNode } from 'react';

export interface PageProps {
  children: ReactNode | ReactChildren;
  padding?: boolean;
  disableOverflow?: boolean;
}

const Container = styled(ContainerBase)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  position: 'relative',
  maxWidth: '100%',
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(10),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 2, 10),
    maxWidth: '100%',
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2, 2, 10),
    maxWidth: '100%',
  },
}));

export const PageContainer = ({ children, padding = true, disableOverflow = false }: PageProps) => {
  return padding ? (
    <Container sx={{ overflowY: disableOverflow ? 'unset' : 'auto' }}>{children}</Container>
  ) : (
    <Box sx={{ overflowY: disableOverflow ? 'unset' : 'auto' }}>{children}</Box>
  );
};

const PageContentBox = styled(Box)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    height: '350px',
    maxHeight: '1000px',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'calc(100% - 250px)', // subtract sidebar width
    maxWidth: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: 'calc(100% - 300px)', // subtract sidebar width
    maxWidth: '100%',
  },
  [theme.breakpoints.up('xl')]: {
    width: 'calc(100% - 400px)', // subtract sidebar width
    maxWidth: '100%',
  },
}));

export const PageContent = ({ children }: PageProps) => {
  return <PageContentBox>{children}</PageContentBox>;
};

const PageSidebarBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  overflow: 'hidden',
  position: 'fixed',
  width: '400px',
  inset: '64px 0px 0px auto',
  height: 'calc(100% - 64px)',
  borderLeft: '1px solid #e6e6e6',
  [theme.breakpoints.down('sm')]: {
    borderLeft: '0',
    borderTop: '1px solid #e6e6e6',
    position: 'relative',
    width: '100%',
    inset: 0,
  },
  [theme.breakpoints.up('sm')]: {
    width: '250px',
  },
  [theme.breakpoints.up('md')]: {
    width: '300px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '400px',
  },
}));

export const PageSidebar = ({ children }: PageProps) => {
  return <PageSidebarBox>{children}</PageSidebarBox>;
};

const PageSidebarContentBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  overflowY: 'auto',
  height: 'calc(100% - 30px)',
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100% - 205px)',
  },
}));

export const PageSidebarContent = ({ children }: PageProps) => {
  return <PageSidebarContentBox>{children}</PageSidebarContentBox>;
};
