import { useMemo } from 'react';
import zxcvbn from 'zxcvbn';

const strengthWords = ['Very weak', 'Weak', 'Fair', 'Strong', 'Very Strong'];

// https://github.com/dropbox/zxcvbn
// scores range from 0 -4, we want 3 or 4
export const MIN_PASSWORD_STRENGTH = 3;

export const MAX_PASSWORD_LENGTH = 128;

export const MIN_PASSWORD_LENGTH = 8;

export type PasswordFeedback = (typeof strengthWords)[number] | 'Too short' | 'Too long';

export type PasswordScore = -1 | 0 | 1 | 2 | 3 | 4;

export interface PasswordStrength {
  feedback: PasswordFeedback;
  score: PasswordScore;
}

export const usePasswordStrength = (
  password: string,
  weakWords: string[] = [],
): PasswordStrength | undefined => {
  return useMemo(() => {
    if (!password) return undefined;

    if (password.length < MIN_PASSWORD_LENGTH) {
      return { score: -1, feedback: 'Too short' };
    }

    if (password.length > MAX_PASSWORD_LENGTH) {
      return { score: -1, feedback: 'Too long' };
    }

    const strength = zxcvbn(password, [...weakWords, 'fanconnect']);
    return {
      score: strength.score,
      feedback: strengthWords[strength.score],
    };
  }, [password, weakWords]);
};
