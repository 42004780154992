import { Tune } from '@mui/icons-material';
import { Button } from '@mui/material';

interface FilterToggleProps {
  active: boolean;
  onToggle: () => void;
}

export const FilterToggle = ({ active, onToggle }: FilterToggleProps) => {
  return (
    <Button
      onClick={onToggle}
      startIcon={<Tune />}
      variant="outlined"
      sx={{
        backgroundColor: active ? 'primary.main' : 'transparent',
        color: active ? 'common.white' : 'inherit',
        '&:hover': {
          backgroundColor: active ? 'primary.main' : 'action.hover',
        },
      }}
    >
      Filter
    </Button>
  );
};
