import { Box, Button, TextField, type DialogProps } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSearchParams } from 'react-router-dom';
import { object, string } from 'yup';
import { useCreateFont } from '~/api/fonts';
import {
  Dialog,
  DialogContentTitle,
  DialogForm,
  DialogTitle,
} from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { useAppContext } from '~/contexts';
import { toDataUri } from '~/lib/file';

const validationSchema = object({
  fileUri: string().required().label('Font'),
  name: string()
    .matches(/^[a-z ]+$/i, 'Letters and spaces only')
    .required()
    .trim()
    .label('Name'),
});

export interface NewFontDialogProps extends DialogProps {
  onAdd: () => void;
}

export const NewFontDialog = ({ onAdd, ...props }: NewFontDialogProps) => {
  const { currentNetwork } = useAppContext();

  const [createFont] = useCreateFont();
  const [selectedFont, setSelectedFont] = useState<File>();

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const formik = useFormik({
    initialValues: { fileUri: '', name: '' },
    onSubmit: async (values) => {
      await createFont({
        variables: {
          input: { ...validationSchema.cast(values), networkId: currentNetwork.id },
          search,
        },
      });
      onAdd();
    },
    validationSchema,
    validateOnChange: true,
  });

  const { getInputProps, open: selectFile } = useDropzone({
    accept: 'font/*, .ttf, .otf, .woff, .woff2', // maybe more?
    multiple: false,
    onDrop: async (accepted, _rejected, _event) => {
      if (accepted.length !== 1) return;
      setSelectedFont(accepted[0]);
      const fileUri = await toDataUri(accepted[0]);
      await formik.setFieldValue('fileUri', fileUri, true);
    },
  });

  return (
    <Dialog {...props}>
      <DialogTitle onClose={() => props.onClose?.({}, 'backdropClick')}>New Font</DialogTitle>

      <DialogContent>
        <DialogContentTitle>Provide a unique name and upload the font file.</DialogContentTitle>

        <DialogForm id="new-font" onSubmit={formik.handleSubmit}>
          <label>
            <span className="label required">Name</span>
            <TextField
              autoFocus
              error={!!formik.errors.name}
              fullWidth
              helperText={formik.errors.name || ''}
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </label>
          <Box sx={{ alignItems: 'center', display: 'flex', '& label': { flexBasis: '25%' } }}>
            <label>
              <span className="label">Font</span>
            </label>
            <Box>
              <input {...getInputProps()} />
              <Button onClick={selectFile} variant="outlined">
                Upload Font
              </Button>
              {selectedFont && <Box>{selectedFont.name}</Box>}
            </Box>
          </Box>
        </DialogForm>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          onClick={() => props.onClose?.({}, 'backdropClick')}
          variant="outlined"
        >
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={!formik.dirty || formik.isSubmitting || !formik.isValid}
          form="new-font"
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
