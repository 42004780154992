import { createContext, useContext, type ReactNode } from 'react';
import { assert } from '~/lib/assert';
import type { DeviceFiltersState } from './';

export type DeviceFiltersContextType = DeviceFiltersState & {
  clearFilters: () => void;
  setFilter: (
    field: {
      [K in keyof DeviceFiltersState]: { field: K; value: DeviceFiltersState[K] };
    }[keyof DeviceFiltersState],
  ) => void;
};

export const DeviceFiltersContext = createContext<DeviceFiltersContextType | undefined>(undefined);

export const useDeviceFilters = () => {
  const context = useContext(DeviceFiltersContext);
  assert(context !== undefined, 'useDeviceFilters must be used with a DeviceFiltersProvider');
  return context;
};

export interface DeviceFiltersProviderProps extends DeviceFiltersContextType {
  children: ReactNode;
}

export const DeviceFiltersProvider = ({ children, ...rest }: DeviceFiltersProviderProps) => (
  <DeviceFiltersContext.Provider value={{ ...rest }}>{children}</DeviceFiltersContext.Provider>
);
